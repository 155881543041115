export default {
  previousMessageUnavailable: "Mensagens anteriores indisponíveis",
  actionPaymentCompleted: "Pagamento concluído",
  actionPaymentError:
    "Ocorreu um erro ao processar o cartão. <br> Tente novamente ou use um outro cartão.",
  actionPostbackError:
    "Ocorreu um erro ao processar o sua ação. Tente novamente.",
  clickToRetry: "Sua mensagem não foi entregue. Clique para tentar novamente.",
  clickToRetryForm:
    "O formulário não foi enviado. Clique em qualquer lugar do formulário para tentar novamente.",
  connectNotificationText:
    "Sincronize sua conversa e continue enviando mensagens pelo seu aplicativo favorito.",
  connectNotificationSingleText:
    "Seja notificado quando você receber uma resposta.",
  conversationListHeaderText: "Minhas conversas",
  conversationListPreviewAnonymousText: "Alguém",
  conversationListPreviewCarouselText: "{user} enviou uma mensagem",
  conversationListPreviewFileText: "{user} enviou um arquivo",
  conversationListPreviewFormText: "{user} enviou um formulário",
  conversationListPreviewFormResponseText: "{user} preencheu um formulário",
  conversationListPreviewImageText: "{user} enviou uma imagem",
  conversationListPreviewLocationRequestText:
    "{user} enviou uma solicitação de localização",
  conversationListPreviewUserText: "Você",
  conversationListRelativeTimeJustNow: "Agora mesmo",
  conversationListRelativeTimeMinute: "há 1 minuto atrás",
  conversationListRelativeTimeMinutes: "há {value} minutos atrás",
  conversationListRelativeTimeHour: "1 hora atrás",
  conversationListRelativeTimeHours: "há {value} horas",
  conversationListRelativeTimeYesterday: "Ontem",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "Offline. Você não receberá mensagens.",
  couldNotConnectRetry: "Reconectando...",
  couldNotConnectRetrySuccess: "Você está online novamente!",
  couldNotLoadConversations: "Não foi possível carregar as conversas.",
  emailChangeAddress: "Alterar meu email",
  emailDescription:
    "Para ser notificado por email quando você receber uma resposta, insira seu endereço de email.",
  emailFieldLabel: "Email",
  emailFieldPlaceholder: "Seu endereço de email",
  emailFormButton: "Enviar como",
  emailLinkingErrorMessage: "Envie um endereço de email válido.",
  fetchHistory: "Carregar mais",
  fetchingHistory: "Recuperando histórico ...",
  fileTooLargeError: "Limite máximo de tamanho de arquivo excedido ({size})",
  fileTypeError: "Tipo de arquivo sem suporte.",
  formErrorInvalidEmail: "O e-mail é inválido",
  formErrorNoLongerThan: "Deve conter, no máximo, ({characters}) caracteres",
  formErrorNoShorterThan: "Deve conter, no mínimo ({characters}) caracteres",
  formErrorUnknown: "Isso não parece certo",
  formFieldSelectPlaceholderFallback: "Escolha uma...",
  frontendEmailChannelDescription:
    "Para falar conosco por email, envie uma mensagem para o nosso endereço de email e responderemos em breve:",
  headerText: "Como podemos ajudá-lo?",
  imageClickToReload: "Clique para recarregar a imagem.",
  imageClickToView: "Clique para visualizar a {size} imagem.",
  imagePreviewNotAvailable: "Visualização indisponível",
  inputPlaceholder: "Digite uma mensagem...",
  inputPlaceholderBlocked: "Preencha o formulário acima ...",
  introAppText: "Envie uma mensagem abaixo ou do seu aplicativo favorito.",
  lineChannelDescription:
    "Para falar conosco usando o LINE, leia esse código QR usando o aplicativo LINE e nos envie uma mensagem.",
  linkError:
    "Ocorreu um erro ao tentar gerar um link para esse canal. Tente novamente.",
  linkChannelPageHeader: "Sincronize sua conversa",
  locationNotSupported:
    "Seu navegador não oferece suporte a serviços de localização ou foi desativado. Em vez disso, digite seu local.",
  locationSecurityRestriction:
    "Este site não pode acessar sua localização. Em vez disso, digite seu local.",
  locationSendingFailed: "Não foi possível enviar localização",
  locationServicesDenied:
    "Este site não pode acessar sua localização. Permita o acesso em suas configurações ou digite sua localização.",
  messageIndicatorTitlePlural: "({count}) novas mensagens",
  messageIndicatorTitleSingular: "({count}) nova mensagem",
  messageRelativeTimeDay: "{value}d atrás",
  messageRelativeTimeHour: "{value}h atrás",
  messageRelativeTimeJustNow: "Agora mesmo",
  messageRelativeTimeMinute: "{value}m atrás",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "Entregues",
  messageSeen: "Visualizada",
  messageSending: "Enviando...",
  messengerChannelDescription:
    "Conecte sua conta do Facebook Messenger para ser notificado quando você receber uma resposta e continuar a conversa no Facebook Messenger.",
  newConversationButtonText: "Nova conversa",
  notificationSettingsChannelsDescription:
    "Sincronize essa conversa conectando -se ao seu aplicativo de mensagens favorito para continuar a conversa do seu jeito.",
  notificationSettingsChannelsTitle: "Outros canais.",
  notificationSettingsConnected: "Conectado",
  notificationSettingsConnectedAs: "Conectado como {username}",
  prechatCaptureGreetingText:
    "Olá 👋\nPara começar, gostaríamos de saber um pouco mais sobre você:",
  prechatCaptureNameLabel: "Nome",
  prechatCaptureNamePlaceholder: "Digite seu nome ...",
  prechatCaptureEmailLabel: "Email",
  prechatCaptureEmailPlaceholder: "por exemplo, nome@empresa.com",
  prechatCaptureConfirmationText: "Obrigado. Como podemos lhe ajudar?",
  prechatCaptureMailgunLinkingConfirmation:
    "Você será notificado aqui e por email em {email} assim que respondermos.",
  privacyLink: "https://www.zendesk.com.br/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "Política de Privacidade",
  sendButtonText: "Enviar",
  settingsHeaderText: "Configurações",
  shareLocation: "Local",
  smsBadRequestError:
    "Não foi possível se comunicar com esse número. Tente novamente ou use outro.",
  smsCancel: "Cancelar",
  smsChangeNumber: "Alterar meu número",
  smsChannelDescription:
    "Conecte seu número de SMS para ser notificado quando você receber uma resposta e continuar a conversa por SMS.",
  smsChannelPendingDescription:
    "Verifique suas mensagens em {number} para confirmar seu número de telefone.",
  smsContinue: "Enviar",
  smsInvalidNumberError: "Envie um número de telefone válido.",
  smsLinkCancelled: "Link para {appUserNumber} foi cancelado.",
  smsLinkPending: "Pendente",
  smsPingChannelError:
    "Ocorreu um erro ao enviar uma mensagem para o seu número.",
  smsSendText: "Me envie uma mensagem de texto",
  smsStartTexting: "Comece a enviar mensagens de texto",
  smsTooManyRequestsError:
    "Uma conexão para esse número foi solicitada recentemente. Tente novamente em {minutes} minutos.",
  smsTooManyRequestsOneMinuteError:
    "Uma conexão para esse número foi solicitada recentemente. Tente novamente em 1 hora.",
  smsUnhandledError: "Algo deu errado. Tente novamente.",
  syncConversation: "Sincronizar conversa",
  tapToRetry: "Sua mensagem não foi entregue. Toque para tentar novamente.",
  tapToRetryForm:
    "O formulário não foi enviado. Toque em qualquer lugar do formulário para tentar novamente.",
  telegramChannelDescription:
    "Conecte sua conta do Telegram para ser notificado quando receber uma resposta e continuar a conversa no Telegram",
  unsupportedMessageType: "Tipo de mensagem sem suporte.",
  unsupportedActionType: "Tipo de ação sem suporte.",
  uploadDocument: "Arquivo",
  uploadInvalidError: "Arquivo inválido",
  uploadPhoto: "Imagem",
  uploadVirusError: "Um vírus foi detectado em seu arquivo e ele foi rejeitado",
  viberChannelDescription:
    "Conecte sua conta do Viber para ser notificado quando você receber uma resposta e continuar a conversa no Viber. Para começar, leia o código QR usando o aplicativo Viber.",
  viberChannelDescriptionMobile:
    "Conecte sua conta do Viber para ser notificado quando você receber uma resposta e continuar a conversa no Viber. Para começar, instale o aplicativo Viber e toque em Conectar.",
  viberQRCodeError: "Erro ao obter seu QR Code do Viber. Tente novamente.",
  wechatChannelDescription:
    "Conecte sua conta do WeChat para ser notificado quando receber uma resposta e continuar a conversa no WeChat. Para começar, leia esse código QR usando o aplicativo WeChat.",
  wechatChannelDescriptionMobile:
    "Conecte sua conta do WeChat para ser notificado quando receber uma resposta e continuar a conversa no WeChat. Para começar, salvar esse QR Code e carregue-o <a href='weixin://dl/scan'>scanner de QR Code.</a>",
  wechatQRCodeError:
    "Ocorreu um erro ao obter seu QR Code do WeChat. Tente novamente.",
  whatsappChannelDescriptionDesktop:
    "Sincronize sua conta com o WhatsApp lendo o QR Code ou clicando no link abaixo.\nEm seguida, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Sua {{code}}).",
  whatsappChannelDescriptionMobile:
    "Sincronize sua conta com o WhatsApp clicando no link abaixo.\nEm seguida, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Sua {{code}}).",
  whatsappLinkingError:
    "Ocorreu um erro ao obter as informações de vinculação do WhatsApp. Tente novamente.",
  zBotErrorBanner: "Não foi possível se conectar ao Z Bot",
  zBotErrorButtonText: "Ir para o formulário da Web",
  zBotErrorMessage:
    "Estamos com um problema técnico e não conseguimos conectá-lo Z Bot. Se você precisar entrar em contato, nosso formulário da Web está pronto para receber sua solicitação.",
};
