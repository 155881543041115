import { error } from './logger';

/**
 * Creates and dispatches a new event with z2suncowidget prefix.
 * @param {string} [eventName="customWidgetEvent"] - Desired name of widget
 * event
 */
const widgetEvent = (eventName = 'customWidgetEvent') => {
  try {
    const event = new Event(`z2suncowidget.${eventName}`);
    window.document.dispatchEvent(event);
  } catch (e) {
    error(`Unable to dispatch widget event: ${e}`);
  }
};

export default widgetEvent;
