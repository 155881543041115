import { clearSessionAuth } from './auth';
import { initialize } from './initializers';

// Iframe related functions

/**
 * Gets the iframe element of the widget.
 * @returns {?HTMLElement} #web-messenger-container iframe element
 */
export const getIframe = () =>
  window.document.getElementById('web-messenger-container');

/**
 * Gets the iframe element's document of the widget.
 * @returns {?HTMLElement} #web-messenger-container iframe element's document
 */
export const getIframeDoc = () => {
  const iframe = getIframe();
  return iframe && (iframe.contentDocument || iframe.contentWindow.document);
};

/**
 * Sets the position of the widget iframe if position parameter given and
 * returns the current position that is set.
 * @param {{?(top: string), ?(right: string), ?(bottom: string), ?(left:
 * string)}} [pixelPosition={}] - Desired pixel position of the widget
 * @returns {{?(top: string), ?(right: string), ?(bottom: string), ?(left:
 * string)}|{}}  Current position of widget or void if no iframe
 */
export const position = (pixelPosition = {}) => {
  const iframe = getIframe();
  const currentPosition = {};
  if (iframe) {
    ['top', 'right', 'bottom', 'left'].forEach((positionName) => {
      currentPosition[positionName] = iframe.style[positionName] || false;
      if (positionName in pixelPosition) {
        iframe.style[positionName] = pixelPosition[positionName];
        currentPosition[positionName] = pixelPosition[positionName];
      }
    });
  }
  return currentPosition;
};

/**
 * Hides the widget iframe.
 */
export const hide = () => {
  const iframe = getIframe();
  if (iframe) {
    iframe.style.display = 'none';
  }
};

/**
 * Checks if the widget iframe is hidden.
 * @returns {boolean} Is the #web-messenger-container iframe hidden
 */
export function hidden() {
  try {
    const iframe = getIframe();
    return iframe.style.display === 'none';
  } catch {
    return true;
  }
}

/**
 * Shows the widget iframe.
 */
export const show = () => {
  const iframe = getIframe();
  if (iframe) {
    iframe.style.display = '';
  }
};

// Z2SuncoWidget related functions

/**
 * Clears the session authentication and re-initializes the widget with the
 * initial options stored in the window.
 */
export const reload = () => {
  clearSessionAuth();
  initialize(window.Z2SunCoWidget.initialOptions);
};

// Smooch related functions

/**
 * If no smooch object, reinitializes the widget and automatically opens on
 * initialization, else shows the widget iframe and opens it after 3/4 second.
 */
export const open = () => {
  if (!window.Smooch || !window.Smooch.open || !getIframe()) {
    window.Z2SunCoWidget.initialOptions.openOnInit = true;
    reload();
  } else if (window.Smooch && window.Smooch.open && getIframe()) {
    show();
    setTimeout(() => {
      window.Smooch.open();
    }, 750);
  }
};

/**
 * Closes the widget: minimizes back to launcher.
 */
export const close = () => {
  if (window.Smooch) {
    window.Smooch.close();
  }
};

/**
 * Destroys the widget: completely removes iframe from page.
 */
export const destroy = () => {
  if (window.Smooch) {
    window.Smooch.destroy();
  }
};

/**
 * Checks if the widget is open.
 * @returns {boolean} Is the widget open
 */
export const opened = () => {
  try {
    return window.Smooch && window.Smooch.isOpened();
  } catch {
    return false;
  }
};

/**
 * Gets the displayed conversation and loads it again.
 */
export const reloadConversation = () => {
  const conversation =
    window.Smooch &&
    window.Smooch.getDisplayedConversation &&
    window.Smooch.getDisplayedConversation();
  if (conversation) {
    window.Smooch.loadConversation(conversation.id);
  }
};
