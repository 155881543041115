export default {
  previousMessageUnavailable: "以前のメッセージは利用できません",
  actionPaymentCompleted: "支払い完了",
  actionPaymentError:
    "カードの処理中にエラーが発生しました。<br> もう一度やり直すか、別のカードを選択してください。",
  actionPostbackError:
    "アクションの処理中にエラーが発生しましたもう一度やり直してください。",
  clickToRetry:
    "メッセージが配信されませんでした。クリックして再試行してください。",
  clickToRetryForm:
    "フォームが送信されていません。フォームの任意の場所をクリックして、再試行してください。",
  connectNotificationText:
    "会話を同期し、お気に入りのアプリから引き続きメッセージを送信してください。",
  connectNotificationSingleText: "返信を受け取ると通知されます。",
  conversationListHeaderText: "マイ会話",
  conversationListPreviewAnonymousText: "誰か",
  conversationListPreviewCarouselText: "{user}さんがメッセージを送信しました",
  conversationListPreviewFileText: "{user}さんがファイルを送信しました",
  conversationListPreviewFormText: "{user}さんがフォームを送信しました",
  conversationListPreviewFormResponseText: "{user}さんがフォームに入力しました",
  conversationListPreviewImageText: "{user}さんが画像を送信しました",
  conversationListPreviewLocationRequestText:
    "{user}が位置情報リクエストを送信しました",
  conversationListPreviewUserText: "エージェント",
  conversationListRelativeTimeJustNow: "たった今",
  conversationListRelativeTimeMinute: "1分前",
  conversationListRelativeTimeMinutes: "{value}分前",
  conversationListRelativeTimeHour: "1時間前",
  conversationListRelativeTimeHours: "{value}時間前",
  conversationListRelativeTimeYesterday: "昨日",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "オフラインです。メッセージの受信は行われません。",
  couldNotConnectRetry: "再接続中...",
  couldNotConnectRetrySuccess: "オンラインに戻りました。",
  couldNotLoadConversations: "会話を読み込めませんでした。",
  emailChangeAddress: "メールアドレスを変更",
  emailDescription:
    "返信が届いたときにメールで通知を受けるには、メールアドレスを入力します。",
  emailFieldLabel: "メール",
  emailFieldPlaceholder: "メールアドレス",
  emailFormButton: "送信",
  emailLinkingErrorMessage: "有効なメールアドレスを送信してください。",
  fetchHistory: "もっと見る",
  fetchingHistory: "履歴を取得しています...",
  fileTooLargeError: "ファイルの最大サイズの制限を超えました（{size}）",
  fileTypeError: "サポートされていないファイルタイプです。",
  formErrorInvalidEmail: "メールアドレスが無効です",
  formErrorNoLongerThan: "（{characters}）文字以内にする必要があります。",
  formErrorNoShorterThan: "（{characters}）文字以上でなければなりません。",
  formErrorUnknown: "これは正しくありません",
  formFieldSelectPlaceholderFallback: "いずれかを選択してください...",
  frontendEmailChannelDescription:
    "メールでお問い合わせの場合は、当社のメールアドレス宛にメッセージをお送りください。すぐに返信いたします。",
  headerText: "お困りですか？",
  imageClickToReload: "クリックして画像を再読み込みします。",
  imageClickToView: "クリックして{size}の画像を表示",
  imagePreviewNotAvailable: "プレビューできません。",
  inputPlaceholder: "メッセージを入力...",
  inputPlaceholderBlocked: "上のフォームに入力してください...",
  introAppText:
    "以下で、またはお気に入りのアプリから、メッセージを送信してください。",
  lineChannelDescription:
    "LINEでお問い合わせの場合は、LINEアプリでこのQRコードをスキャンしてメッセージを送信してください。",
  linkError:
    "このチャネルのリンクを生成するときにエラーが発生しました。もう一度やり直してください。",
  linkChannelPageHeader: "会話を同期",
  locationNotSupported:
    "お使いのブラウザで、位置情報サービスがサポートされいないか無効になっています。代わりに現在地を入力してください。",
  locationSecurityRestriction:
    "このWebサイトはお客様の現在地にアクセスできません。代わりに現在地を入力してください。",
  locationSendingFailed: "場所を送信できませんでした",
  locationServicesDenied:
    "このWebサイトはお客様の現在地にアクセスできません。設定でアクセスを許可するか、代わりに現在地を入力してください。",
  messageIndicatorTitlePlural: "（{count}）件の新規メッセージ",
  messageIndicatorTitleSingular: "（{count}）件の新規メッセージ",
  messageRelativeTimeDay: "（{value}）日前",
  messageRelativeTimeHour: "（{value}）時間前",
  messageRelativeTimeJustNow: "たった今",
  messageRelativeTimeMinute: "（{value}）分前",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "配信済み",
  messageSeen: "既読",
  messageSending: "送信中...",
  messengerChannelDescription:
    "返信が届いたらFacebook Messengerのアカウントに接続して通知を受け取り、Facebook Messengerで会話を続けることができます。",
  newConversationButtonText: "新しい会話",
  notificationSettingsChannelsDescription:
    "お気に入りのメッセージングアプリに接続してこの会話を同期することで、会話を続けることができます。",
  notificationSettingsChannelsTitle: "その他のチャネル",
  notificationSettingsConnected: "接続済み",
  notificationSettingsConnectedAs: "{username}として接続中",
  prechatCaptureGreetingText:
    "こんにちは 👋\nまず、お客様についてもう少し詳しくお聞かせください。",
  prechatCaptureNameLabel: "名前",
  prechatCaptureNamePlaceholder: "名前を入力してください...",
  prechatCaptureEmailLabel: "メール",
  prechatCaptureEmailPlaceholder: "name@company.com",
  prechatCaptureConfirmationText:
    "ありがとうございます。お困りの点を教えてください。",
  prechatCaptureMailgunLinkingConfirmation:
    "ここに通知されるほか、当社から{email}宛てにメールで返信します。",
  privacyLink: "https://www.zendesk.co.jp/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "プライバシーポリシー",
  sendButtonText: "送信",
  settingsHeaderText: "設定",
  shareLocation: "場所",
  smsBadRequestError:
    "この番号に連絡できませんでした。もう一度やり直すか、別のパスワードを使用してください。",
  smsCancel: "キャンセル",
  smsChangeNumber: "電話番号を変更",
  smsChannelDescription:
    "返信が届いたらSMS番号に接続して通知を受け取り、SMSで会話を続けることができます。",
  smsChannelPendingDescription:
    "{number}宛てのメッセージと電話番号を確認します。",
  smsContinue: "送信",
  smsInvalidNumberError: "有効な電話番号を送信してください。",
  smsLinkCancelled: "{appUserNumber}へのリンクがキャンセルされました。",
  smsLinkPending: "保留中",
  smsPingChannelError:
    "お客様の電話番号宛てのメッセージを送信中に、エラーが発生しました。",
  smsSendText: "テキストを送信",
  smsStartTexting: "メッセージ表示を開始",
  smsTooManyRequestsError:
    "その電話番号の接続が最近リクエストされました。{minutes}分後にもう一度試してください。",
  smsTooManyRequestsOneMinuteError:
    "その電話番号の接続が最近リクエストされました。1時間後にもう一度試してださい。",
  smsUnhandledError: "問題が発生しました。もう一度やり直してください。",
  syncConversation: "会話を同期",
  tapToRetry:
    "メッセージが配信されませんでした。再試行するにはタップしてください。",
  tapToRetryForm:
    "フォームが送信されていません。フォームの任意の場所をタップして、再試行してください。",
  telegramChannelDescription:
    "返信が届いたら電報に接続して通知を受け取り、電報で会話を続けることができます。",
  unsupportedMessageType: "サポートされていないメッセージタイプです。",
  unsupportedActionType: "サポートされていないアクションタイプです。",
  uploadDocument: "ファイル",
  uploadInvalidError: "無効なファイル",
  uploadPhoto: "画像",
  uploadVirusError: "ファイル内でウイルスが検出され、拒否されました",
  viberChannelDescription:
    "返信が届いたらViberのアカウントに接続して通知を受け取り、Viberで会話を続けることができます。開始するには、ViberアプリでQRコードをスキャンします。",
  viberChannelDescriptionMobile:
    "返信が届いたらViberのアカウントに接続して通知を受け取り、Viberで会話を続けることができます。開始するには、Viberアプリをインストールし、「Connect」をタップします。",
  viberQRCodeError:
    "ViberでQRコードを取得中にエラーが発生しました。もう一度やり直してください。",
  wechatChannelDescription:
    "返信が届いたらWeChatのアカウントに接続して通知を受け取り、WeChatで会話を続けることができます。開始するには、WeChatアプリでQRコードをスキャンします。",
  wechatChannelDescriptionMobile:
    "返信が届いたらWeChatのアカウントに接続して通知を受け取り、WeChatで会話を続けることができます。開始するには、このQRコードの画像を保存して<a href='weixin://dl/scan'>QRコードスキャナ</a>でアップロードします。",
  wechatQRCodeError:
    "WeChatでQRコードを取得中にエラーが発生しました。もう一度やり直してください。",
  whatsappChannelDescriptionDesktop:
    "QRコードをスキャンするか以下のリンクをクリックして、アカウントをWhatsAppに同期します。\n次に、あらかじめ入力されたメッセージを送信して、同期リクエストを検証します。（お客様の{{code}}）",
  whatsappChannelDescriptionMobile:
    "以下のリンクをクリックして、アカウントをWhatsAppに同期します。\n次に、あらかじめ入力されたメッセージを送信して、同期リクエストを検証します。（お客様の{{code}}）",
  whatsappLinkingError:
    "WhatsAppからリンク情報を取得するときにエラーが発生しました。もう一度やり直してください。",
  zBotErrorBanner: "次に接続できません： Z Bot",
  zBotErrorButtonText: "Webフォームに移動",
  zBotErrorMessage:
    "技術的な問題が発生しているため、 Z Bot に接続できません。ご連絡が必要な場合は、Webフォームでリクエストを受け付けます。",
};
