// eslint-disable-next-line import/no-extraneous-dependencies
import { retry as lifeomicRetry } from '@lifeomic/attempt';
import { hideErrorBanner, showErrorBanner } from './failover';
import { error, warn } from '../lib/logger';
import widgetEvent from '../lib/events';
import { hidden, show } from './api';

const Z2_SUNCO_AUTH_KEY = 'z2_sunco_widget_auth';
let jwtAuth = false;
let suncoAuth = false;
let guideToken = null;

/**
 * Gets JWT auth state value.
 * @returns {boolean} JWT Auth value
 */
export const getJwtAuth = () => jwtAuth;

/**
 * Sets JWT auth state value.
 * @param {boolean} authenticated - Is authenticated
 */
export const setJwtAuth = (authenticated) => {
  if (authenticated) {
    jwtAuth = true;
  } else {
    jwtAuth = false;
  }
};

/**
 * Gets SunCo auth state value.
 * @returns {boolean} SunCo Auth value
 */
export const getSuncoAuth = () => suncoAuth;

/**
 * Sets SunCo auth state value.
 * @param {boolean} loggedIn - Is logged in
 */
export const setSuncoAuth = (loggedIn) => {
  if (loggedIn) {
    suncoAuth = true;
  } else {
    suncoAuth = false;
  }
};

/**
 * Checks if the current URL includes '/hc' (help center) in the pathname.
 * @returns {boolean} True if the current URL includes '/hc', false otherwise.
 */
export const isHelpCenter = () =>
  window.document.location.pathname.includes('/hc');

/**
 * Gets Guide token value.
 * @returns {string|null} Guide token value
 */
export const getGuideToken = () => guideToken;

/**
 * Sets Guide token value.
 * @param {string|null} sessionToken - Help center user session token
 */
export const setGuideToken = (sessionToken) => {
  guideToken = sessionToken;
};

/**
 * Checks if cookies blocked, then gets and parses the auth item from session
 * storage. If nothing found, return auth object with null values.
 * @returns {({externalId: ?string, jwt: ?string, productInstanceJwt:
 * ?string}|{})} Session auth object with external id and jwts
 */
export const getSessionAuth = () => {
  const defaultAuth = { externalId: null, jwt: null, productInstanceJwt: null };
  try {
    return (
      JSON.parse(window.sessionStorage.getItem(Z2_SUNCO_AUTH_KEY)) ||
      defaultAuth
    );
  } catch {
    warn('Unable to get item in session storage, proceeding without caching');
    return defaultAuth;
  }
};

/**
 * Sets session authentication with credentials and uses session storage for
 * caching.
 * @param {{externalId: ?string, jwt: ?string, productInstanceJwt: ?string}}
 * credentials - Session authentication credentials
 */
export const setSessionAuth = ({
  externalId = null,
  jwt = null,
  productInstanceJwt = null,
}) => {
  try {
    window.sessionStorage.setItem(
      Z2_SUNCO_AUTH_KEY,
      JSON.stringify({ externalId, jwt, productInstanceJwt })
    );
  } catch {
    warn('Unable to set item in session storage, proceeding without caching.');
  }
};

/**
 * Checks if cookies blocked, then removes auth item from session storage.
 */
export const clearSessionAuth = () => {
  try {
    window.sessionStorage.removeItem(Z2_SUNCO_AUTH_KEY);
  } catch {
    warn(
      'Unable to remove item in session storage, proceeding with persistant cache.'
    );
  }
};

/**
 * Attempts to fetch a signed token based on the current logged-in user's
 * information from the help center for Z2 HC Persistent Conversations.
 * @returns {Promise<{token: string}>} Promise that resolves to an object
 * containing a signed token based on the user information
 */
export const fetchHelpCenterToken = async () => {
  // If guideToken is already set, return it instead of fetching a new one
  try {
    const res = await fetch(`/hc/api/v2/integration/token`);
    const session = await res.json();
    return session.token;
  } catch (e) {
    if (e.status !== 401) {
      warn(
        `Unable to retrieve signed help center user token, proceeding as unauthenticated: ${e}`
      );
    }
    clearSessionAuth();
    return null;
  }
};

/**
 * Checks whether the session needs to be reset based on the auth status,
 * whether the environment is help center and whether the guide product is
 * active. If `externalId` or `jwt` does not exist, it immediately ends the
 * check and returns these values.
 * @returns {Promise<[string|null, string|null]>} A Promise that resolves to an
 * array containing updated externalId and jwt, which can be null if session
 * auth was cleared.
 */
const resetSessionIfRequired = async () => {
  // Fetch session authorization details
  const { externalId, jwt } = getSessionAuth();
  try {
    // If either authorization details doesn't exist, return them immediately
    if (!externalId || !jwt) {
      return [null, null];
    }
    // Check whether the guide product 'z2Guide' is included in the externalId
    const guideProduct = externalId ? externalId.includes('z2Guide') : null;
    /* If the environment is a help center, but either guideProduct doesn't
    exist or no guideToken value is found, clear the session auth and return
    empty values */
    if (isHelpCenter() && !guideProduct) {
      clearSessionAuth();
      return [null, null];
    }
    // If the environment is not help center, but guideProduct exists, clear
    // session auth and return empty values
    if (!isHelpCenter() && guideProduct) {
      clearSessionAuth();
      return [null, null];
    }
    // If none of the conditions above are met, return the current externalId
    // and jwt
    return [externalId, jwt];
  } catch (e) {
    // Warn and proceed with persistent cache in case any error occurs during
    // the session reset requirement checks
    warn(
      'Unable to determine session reset requirements, proceeding with persistent cache.'
    );
    return [externalId, jwt];
  }
};

/**
 * Checks in meta tags for csrf token, if none found, fetches authenticity token
 * from users/me endpoint to use as csrf token.
 * @returns {(?string) | (Promise<?string>)} Returns Promise with csrf token
 * @throws When unable to fetch authenticity token from users/me endpoint
 */
const fetchCSRFToken = async () => {
  // Try to fetch from csrf token from page first, else call users/me endpoint
  try {
    const { content } = document.querySelector('meta[name="csrf-token"]');
    return content;
  } catch {
    try {
      const res = await fetch(`/api/v2/users/me`);
      const { user } = await res.json();
      return user.authenticity_token;
    } catch (e) {
      throw new Error(`Unable to fetch user: ${e}`);
    }
  }
};

/**
 * Fetches JWT with given url using either a provided or fetched csrf token
 * @param {string} jwtUrl - Url for JWT authentication
 * @param {?string} csrfToken - Cross site forgery request token
 * @param {?string} sessionToken - Help center user session token
 * @returns {Promise<({externalId: ?string, jwt: ?string, productInstanceJwt:
 * ?string}|any>} Promise with JWT JSON object result or null
 * @throws When unable to fetch a JWT
 */
const fetchJwt = async (jwtUrl, csrfToken, sessionToken) => {
  let userToken = csrfToken;
  if (typeof jwtUrl === 'function') {
    return jwtUrl();
  }
  if (!csrfToken) {
    userToken = await fetchCSRFToken();
  }
  const res = await fetch(jwtUrl, {
    method: jwtUrl.includes('sunco_jwt_proxy') ? 'GET' : 'POST',
    headers: {
      'X-CSRF-Token': userToken,
      'x-jwt-guide-token': sessionToken,
    },
  });
  if (res.ok) {
    const jwtToken = await res.json();
    setJwtAuth(true);
    return jwtToken;
  }
  throw new Error('Unable to Fetch JWT');
};

/**
 * Uses @lifeomic/attempt for handling retries to fetch a JWT with exponential
 * backoff and jitter. Aborts when there is not a retryable error
 * @param {string} jwtUrl - Url for JWT authentication
 * @param {?string} csrfToken - Cross site forgery request token
 * @returns {?Promise<({externalId: ?string, jwt: ?string, productInstanceJwt:
 * ?string}|any>} Returns null if the maximum number of attempts has been
 * reached, otherwise returns a Promise with the resulting object of the fetch
 * JWT call.
 */
const retryFetchJwt = (jwtUrl, csrfToken) =>
  lifeomicRetry(
    async () =>
      // Need to run asynchronously in the background
      // eslint-disable-next-line no-return-await
      await fetchJwt(
        jwtUrl,
        csrfToken,
        isHelpCenter() && (await fetchHelpCenterToken())
      ),
    {
      delay: 400,
      factor: 2,
      maxAttempts: 5,
      minDelay: 0,
      maxDelay: 10000,
      jitter: true,
      timeout: 5000,
      async handleError(err, context) {
        if (err.retryable === false) {
          error(`Bad request, unable to retry.`);
          context.abort();
        }
      },
      async handleTimeout(context) {
        error(`Network request timeout reached.`);
        context.abort();
      },
    }
  );

// Unable to login, proceed with unauthenticated and show widget launcher
/**
 * Sets session auth to empty, disables JWT authentication state, and shows
 * widget launcher and error banner
 */
const onUnauthenticated = () => {
  setSessionAuth({});
  setJwtAuth(false);
  setSuncoAuth(false);
  if (hidden()) show();
  showErrorBanner('Previous Messages Unavailable');
};

/**
 * Handles invalid authentication processes which include: setting the
 * authentication state, displaying error banners, attempting a JWT fetch, and
 * JWT fetch retry attempts. In case of failure to fetch, this function proceeds
 * as unauthenticated.
 * @param {{jwtUrl: ?string, csrfToken: ?string}} - Authentication parameters
 * @param {boolean} [retryFetch=false] - Retry fetching JWT in background
 * @returns {Promise<String|null>|null} If the jwtUrl is not provided, the
 * function returns null immediately. Otherwise, it returns a Promise. If the
 * Promise is resolved successfully, it will contain the JWT string. If any
 * error occurs, the Promise will be resolved with a value of null.
 */
export const onInvalidAuth = async (
  { jwtUrl, csrfToken },
  retryFetch = false
) => {
  if (!jwtUrl) return null;
  try {
    setJwtAuth(false);
    showErrorBanner('Previous Messages Unavailable');
    const auth = await fetchJwt(
      jwtUrl,
      csrfToken,
      isHelpCenter() && (await fetchHelpCenterToken())
    );
    setSessionAuth(auth);
    return auth.jwt;
  } catch (e) {
    if (retryFetch) {
      return retryFetchJwt(jwtUrl, csrfToken)
        .then((retryAuth) => {
          setSessionAuth(retryAuth);
          return retryAuth.jwt;
        })
        .catch(() => {
          // If unable to log in, proceed as unauthenticated
          error(`Retry limit exceeded, unable to fetch JWT: ${e}`);
          onUnauthenticated();
        });
    }
    // If no retry, proceed as unauthenticated
    onUnauthenticated();

    return null;
  }
};

/**
 * Logs login failures to console and creates error events based on status
 * codes, if none present, proceed as unauthenticated
 * @param {Error} e - Error to process on login failure
 */
const onLoginFailure = (e) => {
  error('Error Authenticating User:', {
    error: e.toString(),
    stack: e.stack,
  });
  if (e.status) {
    widgetEvent('error');
  } else {
    onUnauthenticated();
  }
};

/**
 * Handles first time and repeated login attempts to SunCo as well as login
 * failures.
 * @param {string} jwtUrl - Url for JWT authentication
 * @param {?string} csrfToken - Cross site forgery request token
 * @param {boolean} [retry=false] - Attempt login with retry
 */
export const login = (jwtUrl, csrfToken, retry = false) => {
  // Check if we need to reset the session auth
  resetSessionIfRequired()
    .then(([externalId, jwt]) => {
      // Check for auth credentials
      if (externalId && jwt) {
        // If not yet authenticated with sunco, login, else do nothing proceed
        // as unauthenticated
        if (!getSuncoAuth()) {
          window.Smooch.login(externalId, jwt)
            .then(() => {
              setSuncoAuth(true);
              hideErrorBanner();
            })
            .catch((e) => {
              onLoginFailure(e);
            });
        }
      } else {
        // Handle first time authentication
        onInvalidAuth({ jwtUrl, csrfToken }, retry)
          .then((auth) => {
            // Call login with new authentication credentials once
            if (auth) login(auth.jwt);
          })
          .catch((e) => {
            onLoginFailure(e);
          });
      }
    })
    .catch((e) => {
      onLoginFailure(e);
    });
};
