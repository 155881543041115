import {
  addCustomWidgetControls,
  bindMutationObserver,
  bindNewMessageListeners,
  bindResizeObserver,
} from './ui-helpers';
import {
  clearSessionAuth,
  getJwtAuth,
  isHelpCenter,
  login,
  onInvalidAuth,
} from './auth';
import { configureEmbed } from '../lib/embed';
import {
  hide,
  open,
  position as apiPosition,
  destroy,
  getIframe,
  getIframeDoc,
} from './api';
import translations from '../translations';
import widgetEvent from '../lib/events';
import { error } from '../lib/logger';
import botWidgetIconBlinking from '../images/blinking.gif';
import botWidgetIcon from '../images/default.gif';
import agentIconUrl from '../images/default-agent.png';
import {
  addAccessibleControls,
  checkForLocale,
  updateLauncherIndex,
} from '../lib/accessibility';
import { getFailoverState, hideErrorBanner } from './failover';
import { getIntegrationId } from '../lib/env';

// eslint-disable-next-line import/no-extraneous-dependencies
const negotiateLocale = require('@zendesk/client-i18n-tools/src/negotiateLocale');
const productCss = require('../styles/sunco-widget-product.css');
const widgetCss = require('../styles/sunco-widget.css');

const css = [widgetCss, productCss].join('\n');

// Prevents multiple initializations from occuring
let isInitializing = false;

/**
 * Opens the widget automatically when the help_widget url parameter is present
 * and true.
 * @param {string} deepLinkParam
 */
const openWhenDeepLinkPresent = (deepLinkParam) => {
  const isDeepLinkParamPresent = !!window.location.search
    .slice(1)
    .split('&')
    .find((queryParam) => {
      const [key, value] = queryParam.split('=');
      if (key === deepLinkParam) {
        return value === 'true';
      }
      return false;
    });
  if (isDeepLinkParamPresent) {
    open();
  }
};

/**
 * After widget initialization, processes styling, DOM manipulations,
 * accessibility, observers, login, then automatically opens the widget if
 * neccessary and sets up the test environment.
 * @param {{csrfToken: ?string, deepLinkParam: string, inProduct: boolean,
 * inShoppingCart: boolean, jwtUrl: ?string, locale: ?string, openOnInit:
 * boolean, openWidgetToNewConversation: boolean, orientation: string, position:
 * (boolean|{?(top: string), ?(right: string), ?(bottom: string), ?(left:
 * string)}), specificIntent: ?string, widgetInputs: {widgetInput1: ?string,
 * widgetInput2: ?string, widgetInput3: ?string, widgetInput4: ?string,
 * widgetInput5: ?string}, title: ?string}} options
 */
export const afterSunCoInitialization = ({
  csrfToken,
  deepLinkParam,
  inProduct,
  inShoppingCart,
  jwtUrl,
  locale,
  openOnInit,
  openWidgetToNewConversation,
  orientation,
  nonce,
  position,
  specificIntent,
  widgetInputs,
  title
}) => {
  try {
    // Attach the stylesheet to the widget iframe
    const iframe = getIframe();
    const iframeDoc = getIframeDoc();
    const header = iframeDoc.getElementById('header');
    const messengerButton = iframeDoc.getElementById('messenger-button');
    const container = iframeDoc.getElementById('container');
    const stylesheet = window.document.createElement('style');
    stylesheet.rel = 'stylesheet';
    stylesheet.innerHTML = css;
    stylesheet.nonce = nonce;
    iframeDoc.head.appendChild(stylesheet);
    iframe.style.animationFillMode = 'backwards';

    if (title) {
      iframe.setAttribute('title', title);
      iframeDoc.title = title;
    }

    if (orientation === 'left') {
      if (container) container.style['transform-origin'] = 'bottom left';
      messengerButton.style.position = 'fixed';
      messengerButton.style.left = 0;
      messengerButton.style.bottom = 0;
    }
    if (inProduct) {
      hide();
      iframeDoc.body.classList.add('in-product');
      addCustomWidgetControls(header);
    } else {
      iframeDoc.body.classList.add('website');
      if (isHelpCenter() && jwtUrl) {
        iframeDoc.body.classList.add('guide-with-jwt');
        addCustomWidgetControls(header);
      }
    }

    if (position) apiPosition(position);
    addAccessibleControls();
    updateLauncherIndex();

    bindNewMessageListeners({
      csrfToken,
      inProduct,
      inShoppingCart,
      jwtUrl,
      locale,
      openOnInit,
      openWidgetToNewConversation,
      specificIntent,
      widgetInputs,
    });
    bindMutationObserver({
      inProduct,
      inShoppingCart,
      locale,
      openWidgetToNewConversation,
      specificIntent,
      widgetInputs,
    });
    bindResizeObserver();
    // Attempt one login after widget initialization and setup, unless in
    // failover
    if (jwtUrl && !getFailoverState()) {
      login(jwtUrl, csrfToken);
    }
    if (openOnInit) {
      open();
    } else {
      openWhenDeepLinkPresent(deepLinkParam);
    }

    isInitializing = false;
    widgetEvent('ready');
  } catch (e) {
    isInitializing = false;
    hide();
    widgetEvent('error');
    error('Error rendering SunCo widget.', {
      error: e.toString(),
      stack: e.stack,
    });
  }
};

/**
 * Before widget initialization, handles translations then initializes.
 * @param {{csrfToken: ?string, deepLinkParam: string, inProduct: boolean,
 * inShoppingCart: boolean, integrationId: string, jwtUrl: ?string, locale:
 * ?string, openOnInit: boolean, openWidgetToNewConversation: boolean,
 * orientation: string, position: (boolean|{?(top: string), ?(right: string),
 * ?(bottom: string), ?(left: string)}), specificIntent: ?string, widgetInputs:
 * {widgetInput1: ?string, widgetInput2: ?string, widgetInput3: ?string,
 * widgetInput4: ?string, widgetInput5: ?string}, title: ?string}} options -
 * Widget initialization options
 */
export const beforeSunCoInitialization = ({
  csrfToken,
  deepLinkParam,
  inProduct,
  inShoppingCart,
  integrationId,
  jwtUrl,
  locale,
  openOnInit,
  openWidgetToNewConversation,
  orientation,
  nonce,
  position,
  specificIntent,
  widgetInputs,
  title
}) => {
  try {
    // Handle translations
    const userLocale = checkForLocale(locale);
    const negotiatedLocale = negotiateLocale({
      locale: userLocale,
      availableLocales: Object.keys(translations),
      defaultLocale: 'en',
    });
    const customText = translations[negotiatedLocale];

    window.Smooch.init({
      integrationId,
      locale: userLocale,
      soundNotificationEnabled: false,
      businessIconUrl: agentIconUrl,
      businessName: 'Zendesk',
      customColors: {
        brandColor: '17494D',
      },
      delegate: {
        // Handles expired JWT values
        onInvalidAuth: () => onInvalidAuth.bind(null, { jwtUrl, csrfToken })(),
        // Handles avatar updates before message is displayed
        beforeDisplay: (message, data) => {
          const currMessage = message;
          if (
            currMessage.source &&
            (currMessage.source === 'ultimate' ||
              (currMessage.source.type &&
                currMessage.source.type === 'ultimate'))
          ) {
            if (data.conversation.lastUpdatedAt === currMessage.received) {
              currMessage.avatarUrl = botWidgetIconBlinking;
            } else {
              currMessage.avatarUrl = botWidgetIcon;
            }
            currMessage.displayName = 'Z Bot';
            // If there's a JWT returned from onInvalidAuth delegate, hide error
            // banner before displaying current message
            if (getJwtAuth()) {
              hideErrorBanner();
            }
          } else if (/data:image/.test(currMessage.avatarUrl)) {
            currMessage.avatarUrl = agentIconUrl;
          }
          return currMessage;
        },
      },
      customText,
      menuItems: {
        imageUpload: true,
        fileUpload: true,
        shareLocation: false,
      },
    })
      .then(() => {
        afterSunCoInitialization({
          csrfToken,
          deepLinkParam,
          inProduct,
          inShoppingCart,
          jwtUrl,
          locale,
          openOnInit,
          openWidgetToNewConversation,
          orientation,
          nonce,
          position,
          specificIntent,
          widgetInputs,
          title
        });
      })
      .catch((e) => {
        isInitializing = false;
        destroy();
        error('Error initializing SunCo widget.', {
          error: e.toString(),
          stack: e.stack,
        });
      });
  } catch (e) {
    isInitializing = false;
    widgetEvent('error');
    error('Error with setup initializing SunCo widget.', {
      error: e.toString(),
      stack: e.stack,
    });
  }
};

/**
 * Handles proper environment integration, auth and/or widget resets, SunCo
 * messenger sdk script insertion, and begins widget initialization process.
 * @param {{csrfToken: ?string, deepLinkParam: string, inProduct: boolean,
 * inShoppingCart: boolean, integrationId: ?string, jwtUrl: ?string, locale:
 * ?string, openOnInit: boolean, openWidgetToNewConversation: boolean,
 * orientation: string, position: (boolean|{?(top: string), ?(right: string),
 * ?(bottom: string), ?(left: string)}), specificIntent: ?string, widgetInputs:
 * {widgetInput1: ?string, widgetInput2: ?string, widgetInput3: ?string,
 * widgetInput4: ?string, widgetInput5: ?string}, title: ?string}} options - Widget
 * initialization options
 * @returns Return if widget is still initializing
 */
export const initialize = ({
  csrfToken = null,
  deepLinkParam = 'help_widget',
  inProduct = false,
  inShoppingCart = false,
  integrationId = null,
  jwtUrl = null,
  locale = null,
  openOnInit = false,
  openWidgetToNewConversation = false,
  orientation = window.document.dir === 'rtl' ? 'left' : 'right',
  position = false,
  resetAuth = false,
  specificIntent = null,
  widgetInputs = {
    widgetInput1: null,
    widgetInput2: null,
    widgetInput3: null,
    widgetInput4: null,
    widgetInput5: null,
  },
  /* eslint no-underscore-dangle: ["error", { "allow": ["__webpack_nonce__"] }] */
  nonce = typeof window.__webpack_nonce__ !== 'undefined'
    ? window.__webpack_nonce__
    : '',
  title = ''
}) => {
  try {
    // Prevent more than one initialization sequence from happening at once.
    if (isInitializing) return;
    isInitializing = true;

    // Determine environment integrationId
    const envIntegrationId = getIntegrationId(integrationId);

    // Ensure new JWT information is retrieved
    if (resetAuth) {
      clearSessionAuth();
    }

    if (window.Smooch) {
      // Reset the SunCo Web Messenger
      window.Smooch.destroy();
    } else {
      // Initialize the SunCo Web Messenger using the embed script
      const $headScript = window.document.createElement('script');
      $headScript.nonce = nonce;
      $headScript.innerHTML = configureEmbed(envIntegrationId);
      window.document.getElementsByTagName('head')[0].appendChild($headScript);
    }

    // Prepare and call the SunCo Web Messenger init handler when appropriate.
    const preparedInitCall = beforeSunCoInitialization.bind(null, {
      csrfToken,
      deepLinkParam,
      inProduct,
      inShoppingCart,
      integrationId: envIntegrationId,
      jwtUrl,
      locale,
      openOnInit,
      openWidgetToNewConversation,
      orientation,
      position,
      specificIntent,
      widgetInputs,
      nonce,
      title
    });
    if (
      ['loaded', 'complete', 'interactive'].includes(window.document.readyState)
    ) {
      preparedInitCall();
    } else {
      window.addEventListener('DOMContentLoaded', preparedInitCall);
    }
  } catch (e) {
    isInitializing = false;
    widgetEvent('error');
    error('Error configuring SunCo widget.', {
      error: e.toString(),
      stack: e.stack,
    });
  }
};
