export default {
  previousMessageUnavailable: "Previous Message Unavailable",
  actionPaymentCompleted: "Payment Completed",
  actionPaymentError:
    "An error occurred while processing the card. <br> Please try again or use a different card.",
  actionPostbackError:
    "An error occurred while processing your action. Please try again.",
  clickToRetry: "Message not delivered. Click to retry.",
  clickToRetryForm: "Form not submitted. Click anywhere on the form to retry.",
  connectNotificationText:
    "Sync your conversation and continue messaging us through your favorite app.",
  connectNotificationSingleText: "Be notified when you get a reply.",
  conversationListHeaderText: "My conversations",
  conversationListPreviewAnonymousText: "Someone",
  conversationListPreviewCarouselText: "{user} sent a message",
  conversationListPreviewFileText: "{user} sent a file",
  conversationListPreviewFormText: "{user} sent a form",
  conversationListPreviewFormResponseText: "{user} filled a form",
  conversationListPreviewImageText: "{user} sent an image",
  conversationListPreviewLocationRequestText: "{user} sent a location request",
  conversationListPreviewUserText: "You",
  conversationListRelativeTimeJustNow: "Just now",
  conversationListRelativeTimeMinute: "1 minute ago",
  conversationListRelativeTimeMinutes: "{value} minutes ago",
  conversationListRelativeTimeHour: "1 hour ago",
  conversationListRelativeTimeHours: "{value} hours ago",
  conversationListRelativeTimeYesterday: "Yesterday",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "Offline. You will not receive messages.",
  couldNotConnectRetry: "Reconnecting...",
  couldNotConnectRetrySuccess: "You're back online!",
  couldNotLoadConversations: "Couldn’t load conversations.",
  emailChangeAddress: "Change my email",
  emailDescription:
    "To be notified by email when you get a reply, enter your email address.",
  emailFieldLabel: "Email",
  emailFieldPlaceholder: "Your email address",
  emailFormButton: "Submit",
  emailLinkingErrorMessage: "Please submit a valid email address.",
  fetchHistory: "Load more",
  fetchingHistory: "Retrieving history...",
  fileTooLargeError: "Max file size limit exceeded ({size})",
  fileTypeError: "Unsupported file type.",
  formErrorInvalidEmail: "Email is invalid",
  formErrorNoLongerThan: "Must contain no more than ({characters}) characters",
  formErrorNoShorterThan: "Must contain at least ({characters}) characters",
  formErrorUnknown: "This doesn't look quite right",
  formFieldSelectPlaceholderFallback: "Choose one...",
  frontendEmailChannelDescription:
    "To talk to us using email just send a message to our email address and we'll reply shortly:",
  headerText: "How can we help?",
  imageClickToReload: "Click to reload image.",
  imageClickToView: "Click to view {size} image.",
  imagePreviewNotAvailable: "Preview not available.",
  inputPlaceholder: "Type a message...",
  inputPlaceholderBlocked: "Complete the form above...",
  introAppText: "Message us below or from your favorite app.",
  lineChannelDescription:
    "To talk to us using LINE, scan this QR code using the LINE app and send us a message.",
  linkError:
    "An error occurred when attempting to generate a link for this channel. Please try again.",
  linkChannelPageHeader: "Sync your conversation",
  locationNotSupported:
    "Your browser does not support location services or it’s been disabled. Please type your location instead.",
  locationSecurityRestriction:
    "This website cannot access your location. Please type your location instead.",
  locationSendingFailed: "Could not send location",
  locationServicesDenied:
    "This website cannot access your location. Allow access in your settings or type your location instead.",
  messageIndicatorTitlePlural: "({count}) New messages",
  messageIndicatorTitleSingular: "({count}) New message",
  messageRelativeTimeDay: "{value}d ago",
  messageRelativeTimeHour: "{value}h ago",
  messageRelativeTimeJustNow: "Just now",
  messageRelativeTimeMinute: "{value}m ago",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "Delivered",
  messageSeen: "Seen",
  messageSending: "Sending...",
  messengerChannelDescription:
    "Connect your Facebook Messenger account to be notified when you get a reply and continue the conversation on Facebook Messenger.",
  newConversationButtonText: "New conversation",
  notificationSettingsChannelsDescription:
    "Sync this conversation by connecting to your favorite messaging app to continue the conversation your way.",
  notificationSettingsChannelsTitle: "Other Channels",
  notificationSettingsConnected: "Connected",
  notificationSettingsConnectedAs: "Connected as {username}",
  prechatCaptureGreetingText:
    "Hi there 👋\nTo start off, we'd like to know a little bit more about you:",
  prechatCaptureNameLabel: "Your name",
  prechatCaptureNamePlaceholder: "Type your name...",
  prechatCaptureEmailLabel: "Email",
  prechatCaptureEmailPlaceholder: "name@company.com",
  prechatCaptureConfirmationText: "Thanks for that! What can we help you with?",
  prechatCaptureMailgunLinkingConfirmation:
    "You'll be notified here and by email at {email} once we reply.",
  privacyLink: "https://www.zendesk.com/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "Privacy Policy",
  sendButtonText: "Send",
  settingsHeaderText: "Settings",
  shareLocation: "Location",
  smsBadRequestError:
    "We were unable to communicate with this number. Try again or use a different one.",
  smsCancel: "Cancel",
  smsChangeNumber: "Change my number",
  smsChannelDescription:
    "Connect your SMS number to be notified when you get a reply and continue the conversation over SMS.",
  smsChannelPendingDescription:
    "Check your messages at {number} to confirm your phone number.",
  smsContinue: "Send",
  smsInvalidNumberError: "Please submit a valid phone number.",
  smsLinkCancelled: "Link to {appUserNumber} was cancelled.",
  smsLinkPending: "Pending",
  smsPingChannelError: "There was an error sending a message to your number.",
  smsSendText: "Send me a text",
  smsStartTexting: "Start Texting",
  smsTooManyRequestsError:
    "A connection for that number was requested recently. Please try again in {minutes} minutes.",
  smsTooManyRequestsOneMinuteError:
    "A connection for that number was requested recently. Please try again in 1 minute.",
  smsUnhandledError: "Something went wrong. Please try again.",
  syncConversation: "Sync conversation",
  tapToRetry: "Message not delivered. Tap to retry.",
  tapToRetryForm: "Form not submitted. Tap anywhere on the form to retry.",
  telegramChannelDescription:
    "Connect your Telegram account to be notified when you get a reply and continue the conversation on Telegram",
  unsupportedMessageType: "Unsupported message type.",
  unsupportedActionType: "Unsupported action type.",
  uploadDocument: "File",
  uploadInvalidError: "Invalid file",
  uploadPhoto: "Image",
  uploadVirusError:
    "A virus was detected in your file and it has been rejected",
  viberChannelDescription:
    "Connect your Viber account to be notified when you get a reply and continue the conversation on Viber. To get started, scan the QR code using the Viber app.",
  viberChannelDescriptionMobile:
    "Connect your Viber account to be notified when you get a reply and continue the conversation on Viber. To get started, install the Viber app and tap Connect.",
  viberQRCodeError:
    "An error occurred while fetching your Viber QR code. Please try again.",
  wechatChannelDescription:
    "Connect your WeChat account to be notified when you get a reply and continue the conversation on WeChat. To get started, scan this QR code using the WeChat app.",
  wechatChannelDescriptionMobile:
    "Connect your WeChat account to be notified when you get a reply and continue the conversation on WeChat. To get started, save this QR code image and upload it in the <a href='weixin://dl/scan'>QR code scanner</a>.",
  wechatQRCodeError:
    "An error occurred while fetching your WeChat QR code. Please try again.",
  whatsappChannelDescriptionDesktop:
    "Sync your account to WhatsApp by scanning the QR code or clicking the link below.\nThen, send the pre-populated message to validate the sync request. (Your code: {{code}}).",
  whatsappChannelDescriptionMobile:
    "Sync your account to WhatsApp by clicking the link below.\nThen, send the pre-populated message to validate the sync request. (Your code: {{code}}).",
  whatsappLinkingError:
    "An error occurred while fetching your WhatsApp linking information. Please try again.",
  zBotErrorBanner: "Unable to connect to Z Bot",
  zBotErrorButtonText: "Go to Web Form",
  zBotErrorMessage:
    "We're experiencing a technical issue and can't connect you to Z Bot. If you need to get in touch, our web form is ready to receive your request.",
};
