export default {
  previousMessagesUnavailable: "Mensajes anteriores no disponibles",
  paymentCompleted: "Pago completado",
  actionPaymentError:
    "Ocurrió un error al procesar la tarjeta. <br> Vuelva a intentarlo o utilice otra tarjeta.",
  actionPostbackError:
    "Ocurrió un error al procesar la acción. Vuelva a intentarlo.",
  clickToRetry:
    "El mensaje no se pudo entregar. Haga clic para volver a intentar.",
  clickToRetryForm:
    "No se envió el formulario. Haga clic en cualquier lugar del formulario para volver a intentarlo.",
  connectNotificationText:
    "Sincronice su conversación y continúe enviándonos mensajes a través de su aplicación favorita.",
  connectNotificationSingleText:
    "Reciba una notificación cuando reciba una respuesta.",
  conversationListHeaderText: "Mis conversaciones",
  conversationListPreviewAnonymousText: "Alguien",
  conversationListPreviewCarouselText: "{user} envió un mensaje",
  conversationListPreviewFileText: "{user} envió un archivo",
  conversationListPreviewFormText: "{user} envió un formulario",
  conversationListPreviewFormResponseText: "{user} completó un formulario",
  conversationListPreviewImageText: "{user} envió una imagen",
  conversationListPreviewLocationRequestText:
    "{user} envió una solicitud de ubicación",
  conversationListPreviewUserText: "Usted",
  conversationListRelativeTimeJustNow: "Hace un momento",
  conversationListRelativeTimeMinute: "hace 1 minuto",
  conversationListRelativeTimeMinutes: "hace {value} minutos",
  conversationListRelativeTimeHour: "Hace 1 hora",
  conversationListRelativeTimeHours: "hace {value} horas",
  conversationListRelativeTimeYesterday: "Ayer",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "Desconectado. No recibirá mensajes.",
  couldNotConnectRetry: "Volviendo a conectarse...",
  couldNotConnectRetrySuccess: "Se volvió a conectar.",
  couldNotLoadConversations: "No se pudieron cargar las conversaciones.",
  emailChangeAddress: "Cambiar mi correo electrónico",
  emailDescription:
    "Para recibir una notificación por correo electrónico cuando reciba una respuesta, ingrese su dirección de correo electrónico.",
  emailFieldLabel: "Correo electrónico",
  emailFieldPlaceholder: "Su dirección de correo electrónico",
  emailFormButton: "Enviar",
  emailLinkingErrorMessage:
    "Ingrese una dirección de correo electrónico válida.",
  fetchHistory: "Cargar más",
  fetchingHistory: "Recuperando historial...",
  fileTooLargeError: "Se superó el límite de tamaño de archivo máximo ({size})",
  fileTypeError: "Tipo de archivo no admitido.",
  formErrorInvalidEmail: "La dirección de correo electrónico no es válida",
  formErrorNoLongerThan: "No puede contener más de ({characters}) caracteres",
  formErrorNoShorterThan: "Debe contener al menos ({characters}) caracteres",
  formErrorUnknown: "Esto no parece correcto",
  formFieldSelectPlaceholderFallback: "Elija uno...",
  frontendEmailChannelDescription:
    "Para hablar con nosotros por correo electrónico, simplemente envíe un mensaje a nuestra dirección de correo electrónico y le responderemos pronto:",
  headerText: "¿En qué podemos ayudarle?",
  imageClickToReload: "Haga clic para volver a cargar la imagen.",
  imageClickToView: "Haga clic para ver la  imagen {size}.",
  imagePreviewNotAvailable: "Vista previa no disponible.",
  inputPlaceholder: "Escriba un mensaje...",
  inputPlaceholderBlocked: "Complete el formulario de arriba...",
  introAppText:
    "Envíenos un mensaje a continuación o desde su aplicación favorita.",
  lineChannelDescription:
    "escanee este código QR usando la aplicación LINE y envíenos un mensaje.\"",
  linkError:
    "Ocurrió un error al intentar generar un vínculo para este canal. Vuelva a intentarlo.",
  linkChannelPageHeader: "Sincronice su conversación",
  locationNotSupported:
    "Su navegador no admite los servicios de ubicación o han sido desactivados. En su lugar, debe ingresar su ubicación.",
  locationSecurityRestriction:
    "Este sitio web no puede acceder a su ubicación. En su lugar, debe ingresar su ubicación.",
  locationSendingFailed: "No se pudo enviar la ubicación",
  locationServicesDenied:
    "Este sitio web no puede acceder a su ubicación. Permita el acceso en su configuración o ingrese su ubicación.",
  messageIndicatorTitlePlural: "({count}) Nuevos mensajes",
  messageIndicatorTitleSingular: "({count}) Nuevo mensaje",
  messageRelativeTimeDay: "Hace {value} día(s)",
  messageRelativeTimeHour: "Hace {value} h",
  messageRelativeTimeJustNow: "Hace un momento",
  messageRelativeTimeMinute: "Hace {value} m",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "Entregadas",
  messageSeen: "Visto",
  messageSending: "Enviando...",
  messageChannelDescription:
    "Conecte su cuenta de Facebook Messenger para recibir una notificación cuando reciba una respuesta y continuar la conversación por Facebook Messenger.",
  newConversationButtonText: "Nueva conversación",
  notificationSettingsChannelsDescription:
    "Sincronice esta conversación conectándose a su aplicación de mensajería favorita para continuar la conversación a su manera.",
  notificationSettingsChannelsTitle: "Otros canales",
  notificationSettingsConnected: "Conectado",
  notificationSettingsConnectedAs: "Conectado como {username}",
  prechatCaptureGreetingText:
    "Hola 👋\nPara comenzar, nos gustaría saber un poco más sobre usted:",
  prechatCaptureNameLabel: "Nombre",
  prechatCaptureNamePlaceholder: "Escriba su nombre...",
  prechatCaptureEmailLabel: "Correo electrónico",
  prechatCaptureEmailPlaceholder: "nombre@empresa.com",
  prechatCaptureConfirmationText: "¡Gracias! ¿En qué le podemos ayudar?",
  prechatCaptureMailgunLinkingConfirmation:
    "Se le notificará aquí y por correo electrónico a {email} una vez que respondamos.",
  privacyLink: "https://www.zendesk.es/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "Política de privacidad",
  sendButtonText: "Enviar",
  settingsHeaderText: "Ajustes",
  shareLocation: "Ubicación",
  smsBadRequestError:
    "No pudimos comunicarnos con este número. Vuelva a intentarlo o utilice uno diferente.",
  smsCancel: "Cancelar",
  smsChangeNumber: "Cambiar mi número",
  smsChannelDescription:
    "Conecte su número de SMS para recibir una notificación cuando reciba una respuesta y continúe la conversación por SMS.",
  smsChannelPendingDescription:
    "Revise sus mensajes en {number} para confirmar su número de teléfono.",
  smsContinue: "Enviar",
  smsInvalidNumberError: "Ingrese un número de teléfono válido.",
  smsLinkCancelled: "Se canceló el envío del vínculo a {appUserNumber}.",
  smsLinkPending: "Pendiente",
  smsPingChannelError: "Ocurrió un error al enviar un mensaje a su número.",
  smsSendText: "Enviarme un mensaje de texto",
  smsStartTexting: "Comenzar a enviar mensajes de texto",
  smsTooManyRequestsError:
    "Recientemente se solicitó una conexión para ese número. Vuelva a intentar en {minutes} minutos.",
  smsTooManyRequestsOneMinuteError:
    "Recientemente se solicitó una conexión para ese número. Vuelva a intentar en 1 minuto.",
  smsUnhandledError: "Algo salió mal. Vuelva a intentarlo.",
  syncConversation: "Sincronizar conversación",
  tapToRetry: "El mensaje no se pudo entregar. Toque para volver a intentar.",
  tapToRetryForm:
    "No se envió el formulario. Toque en cualquier lugar del formulario para volver a intentar.",
  telegramChannelDescription:
    "Conecte su cuenta de Telegram para recibir una notificación cuando reciba una respuesta y continuar la conversación por Telegram",
  unsupportedMessageType: "Tipo de mensaje no admitido.",
  unsupportedActionType: "Tipo de acción no admitida.",
  uploadDocument: "Archivo",
  uploadInvalidError: "Archivo no válido",
  uploadPhoto: "Imagen",
  uploadVirusError: "Se detectó un virus en su archivo y ha sido rechazado",
  viberChannelDescription:
    "Conecte su cuenta de Viber para recibir una notificación cuando reciba una respuesta y continuar la conversación por Viber. Para comenzar, escanee el código QR usando la aplicación de Viber.",
  viberChannelDescriptionMobile:
    "Conecte su cuenta de Viber para recibir una notificación cuando reciba una respuesta y continuar la conversación por Viber. Para comenzar, instale la aplicación de Viber y toque Conectar.",
  viberQRCodeError:
    "Ocurrió un error al obtener el código QR de Viber. Vuelva a intentarlo.",
  wechatChannelDescription:
    "Conecte su cuenta de WeChat para recibir una notificación cuando reciba una respuesta y continuar la conversación por WeChat. Para comenzar, escanee este código QR usando la aplicación WeChat.",
  wechatChannelDescriptionMobile:
    "Conecte su cuenta de WeChat para recibir una notificación cuando reciba una respuesta y continuar la conversación por WeChat. Para empezar, guarde esta imagen de código QR y cárguela en el <a href='weixin://dl/scan'>escáner de código QR</a>.",
  wechatQRCodeError:
    "Ocurrió un error al obtener su código QR de WeChat. Vuelva a intentarlo.",
  whatsappChannelDescriptionDesktop:
    "Sincronice su cuenta con WhatsApp escaneando el código QR o haciendo clic en el vínculo a continuación.\nLuego, envíe el mensaje automáticamente rellenado para validar la solicitud de sincronización. (Su {{code}}).",
  whatsappChannelDescriptionMobile:
    "Sincronice su cuenta con WhatsApp haciendo clic en el vínculo a continuación.\nLuego, envíe el mensaje automáticamente rellenado para validar la solicitud de sincronización. (Su {{code}}).",
  whatsappLinkingError:
    "Ocurrió un error al obtener la información de vinculación de WhatsApp. Vuelva a intentarlo.",
  zBotErrorBanner: "No es posible conectarse con Z Bot",
  zBotErrorButtonText: "Ir al formulario web",
  zBotErrorMessage:
    "Tenemos un problema técnico y no podemos conectarlo con Z Bot. Si necesita contactarnos, nuestro formulario web está listo para recibir su solicitud.",
};
