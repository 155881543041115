import widgetEvent from './events';
import { getConversationMetadata } from './metadata';
import { error } from './logger';
import { checkForLocale } from './accessibility';
import { getLoadingState, showLoadingIndicator } from './loaders';

let conversationsCountOnWidgetLoad = 0;

/**
 * Gets the async count of conversations
 * @returns {int} Async conversation count present at widget load
 */
export const getConversationsCountOnWidgetLoad = () =>
  conversationsCountOnWidgetLoad;

/**
 * Sets the async count of conversations
 * @param {boolean} currentCount - The current count of conversations at widget load
 */
export const setConversationsCountOnWidgetLoad = (currentCount) => {
  conversationsCountOnWidgetLoad = currentCount;
};

/**
 * Checks to see if conversation creation is needed based on number of exisiting
 * conversations.
 * @returns {boolean} Zero conversations or not
 */
export const shouldCreateConversation = () =>
  window.Smooch.getConversations().length === 0;

/**
 * If necessary, sets defaults values for options parameters, then uses said
 * parameters to update conversation metadata that is then used to create a new
 * SunCo conversation, after which, a conversation will be loaded if necessary.
 * @param {{inProduct: boolean, inShoppingCart: boolean, locale: string,
 * openWidgetToNewConversation: boolean, specificIntent: string, widgetInputs:
 * {widgetInput1: string, widgetInput2: string, widgetInput3: string,
 * widgetInput4: string, widgetInput5: string}}} options - Conversation metadata
 */
export const createConversation = async ({
  inProduct,
  inShoppingCart,
  locale,
  openWidgetToNewConversation,
  specificIntent,
  widgetInputs,
}) => {
  try {
    const widgetInitialOptions = window.Z2SunCoWidget.initialOptions;
    let conversationIntent = specificIntent;
    let conversationLocale = locale;
    if (!conversationLocale) {
      if (widgetInitialOptions.locale) {
        conversationLocale = widgetInitialOptions.locale;
      } else {
        conversationLocale = checkForLocale();
      }
    }
    if (!conversationIntent) {
      if (widgetInitialOptions.specificIntent) {
        conversationIntent = widgetInitialOptions.specificIntent;
      } else {
        conversationIntent = null;
      }
    }
    const inputs = {
      widgetInput1: null,
      widgetInput2: null,
      widgetInput3: null,
      widgetInput4: null,
      widgetInput5: null,
      ...widgetInputs,
    };
    if (
      !widgetInputs &&
      widgetInitialOptions.widgetInputs &&
      Object.keys(widgetInitialOptions.widgetInputs).length > 0
    ) {
      Object.keys(inputs).forEach((key) => {
        inputs[key] =
          window.Z2SunCoWidget.initialOptions.widgetInputs[key] || null;
      });
    }

    // Attempt to fetch the total number of conversations async at conversation create
    setConversationsCountOnWidgetLoad(window.Smooch.getConversations().length);

    // Start conversation creation, show loader, and load conversation if
    // necessary
    const conversation = await window.Smooch.createConversation({
      metadata: getConversationMetadata(
        inProduct,
        inShoppingCart,
        conversationLocale,
        openWidgetToNewConversation,
        conversationIntent,
        inputs
      ),
    });
    if (
      window.Smooch &&
      window.Smooch.loadConversation &&
      conversation &&
      conversation.id &&
      window.Smooch.getConversations().length > 1
    ) {
      await window.Smooch.loadConversation(conversation.id);
      // Check if loading, then show loading indicator
      if (getLoadingState()) {
        showLoadingIndicator(true, 'conversation');
      }
    }
  } catch (e) {
    widgetEvent('error');
    error('Failed to create conversation.', {
      error: e.toString(),
      stack: e.stack,
    });
  }
};
