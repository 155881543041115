import { getIframeDoc } from '../scripts/api';
import { error } from './logger';
import translations from '../translations';

// eslint-disable-next-line import/no-extraneous-dependencies
const negotiateLocale = require('@zendesk/client-i18n-tools/src/negotiateLocale');

// Controls //

/**
 * Adds custom accessibility classes to the widget controls and updates their
 * tab order.
 * @param {[string, string, string, string, string, string]} controlList - List
 * of control classnames
 */
export const createLinks = (controlList) => {
  if (controlList) {
    try {
      const iframeDoc = getIframeDoc();
      if (iframeDoc) {
        const nodes = iframeDoc.querySelectorAll(controlList);
        nodes.forEach((node) => {
          if (!node.firstChild.classList.contains('accessibility')) {
            const customControl = window.document.createElement('button');
            customControl.classList.add('accessibility');
            if (node.className === 'default-button-container') {
              customControl.classList.add('custom-launcher');
              customControl.tabIndex = 1;
            } else if (node.className === 'conversation-group-item') {
              customControl.classList.add('custom-conversation');
            } else if (node.className === 'image-upload') {
              customControl.classList.add('custom-upload');
            } else if (node.className === 'upload-menu-item') {
              customControl.classList.add('custom-menu');
            } else {
              if (node.className === 'close-handle') {
                customControl.tabIndex = 1;
              } else if (node.className === 'back-handle') {
                customControl.tabIndex = 1;
              }
              customControl.classList.add('custom-button');
            }
            node.insertBefore(customControl, node.firstChild);
          }
        });
      }
    } catch (e) {
      error(`Failed to add widget accessibility nodes: ${e}`);
    }
  }
};

/**
 * Creates list of controls that need accessibility and links for said widget
 * controls to ensure proper tabbing order.
 */
export const addAccessibleControls = () => {
  const controlList = [
    '.back-handle',
    '.close-handle',
    '.conversation-group-item',
    '.default-button-container',
    '.image-upload',
    '.upload-menu-item',
  ];
  createLinks(controlList);
};

/**
 * Allows the widget launcher to be clickable when focused on Help Center login
 * modal element.
 */
export const updateLauncherIndex = () => {
  const webMessangerContainer = window.document.getElementById(
    'web-messenger-container'
  );
  if (webMessangerContainer) {
    webMessangerContainer.style.zIndex = '999999';
  }
};

// Language //

/**
 * Checks if locale was passed, if not, check the window document then the I18N
 * window object, and if none are found, default to english.
 * @param {?string} [locale="en"] - Widget locale
 * @returns {string} Determined locale
 */
export const checkForLocale = (locale) => {
  let currLocale = locale;
  // Check for null and undefined locale
  if (locale != null) {
    return locale.toLowerCase();
  }
  // Check for lang attribute on <html>
  if (window.document.documentElement.lang) {
    currLocale = window.document.documentElement.lang;
  } else if (window.I18N) {
    currLocale = window.I18N.locale;
  } else {
    currLocale = 'en';
  }
  return currLocale.toLowerCase();
};

/**
 * Checks the locale and negotiates it with client-i18n-tools in order to
 * properly fetch the correct translations text.
 * @returns {Object} Translated text
 */
export const getTranslatedText = () => {
  const userLocale = checkForLocale();
  const negotiatedLocale = negotiateLocale({
    locale: userLocale,
    availableLocales: Object.keys(translations),
    defaultLocale: 'en',
  });
  return translations[negotiatedLocale];
};

/**
 * On widget error, gets the locale and then uses Zendesk's client tools to
 * negotiate the locale. Once locale is determined, translates to that locale
 * and returns the translated text.
 * @returns {{bannerText: string, buttonText: string, message: string}}
 * Translated text for error banner, error button, and error message
 */
export const errorTranslations = () => {
  const customText = getTranslatedText();
  return {
    bannerText:
      customText && customText.zBotErrorBanner
        ? customText.zBotErrorBanner
        : 'Unable to connect to Z Bot',
    buttonText:
      customText && customText.zBotErrorButtonText
        ? customText.zBotErrorButtonText
        : 'Go to Web Form',
    message:
      customText && customText.zBotErrorMessage
        ? customText.zBotErrorMessage
        : "We're experiencing a technical issue and can't connect you to Z Bot. If you need to get in touch, our web form is ready to receive your request.",
  };
};
