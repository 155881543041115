export default {
  previousMessageUnavailable: "之前的消息不可用",
  actionPaymentCompleted: "付款完成",
  actionPaymentError: "处理此卡时发生错误。<br> 请重试或换张卡。",
  actionPostbackError: "处理您的操作时发生错误。请重试。",
  clickToRetry: "消息未送达。单击以重试。",
  clickToRetryForm: "表单未提交。单击表单中任何地方以重试。",
  connectNotificationText:
    "通过您最喜欢的应用程序同步您的对话并继续给我们发送消息。",
  connectNotificationSingleText: "在您得到回复时收到相应通知。",
  conversationListHeaderText: "我的对话",
  conversationListPreviewAnonymousText: "某人",
  conversationListPreviewCarouselText: "{user} 发送了消息",
  conversationListPreviewFileText: "{user} 发送了文件",
  conversationListPreviewFormText: "{user} 发送了表格",
  conversationListPreviewFormResponseText: "{user} 填写了表格",
  conversationListPreviewImageText: "{user} 发送了图像",
  conversationListPreviewLocationRequestText: "{user} 发送了位置请求",
  conversationListPreviewUserText: "您",
  conversationListRelativeTimeJustNow: "刚才",
  conversationListRelativeTimeMinute: "1 分钟前",
  conversationListRelativeTimeMinutes: "{value} 分钟前",
  conversationListRelativeTimeHour: "1 小时前",
  conversationListRelativeTimeHours: "{value} 小时前",
  conversationListRelativeTimeYesterday: "昨天",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "离线。您将不会接收到消息。",
  couldNotConnectRetry: "正在重新连接......",
  couldNotConnectRetrySuccess: "您已重新回到在线状态！",
  couldNotLoadConversations: "无法加载对话。",
  emailChangeAddress: "更改我的电邮地址",
  emailDescription: "如要在得到回复时收到相应电邮通知，请输入您的电邮地址。",
  emailFieldLabel: "电邮",
  emailFieldPlaceholder: "您的电邮地址",
  emailFormButton: "提交",
  emailLinkingErrorMessage: "请提交有效的电邮地址。",
  fetchHistory: "加载更多",
  fetchingHistory: "正在检索历史记录......",
  fileTooLargeError: "已超过最大文件大小限制 ({size})",
  fileTypeError: "不受支持的文件类型。",
  formErrorInvalidEmail: "电邮无效",
  formErrorNoLongerThan: "必须包含最多 ({characters}) 个字符",
  formErrorNoShorterThan: "必须包含至少 ({characters}) 个字符",
  formErrorUnknown: "这似乎不太对",
  formFieldSelectPlaceholderFallback: "选择一个......",
  frontendEmailChannelDescription:
    "如要使用电邮与我们交谈，只需发送消息到我们的电邮地址，我们会尽快回复：",
  headerText: "需要帮助吗？",
  imageClickToReload: "单击以重新加载图像。",
  imageClickToView: "单击查看 {size} 图像。",
  imagePreviewNotAvailable: "预览不可用。",
  inputPlaceholder: "输入消息......",
  inputPlaceholderBlocked: "填写上述表单......",
  introAppText: "请在下方或从您最喜欢的应用程序给我们发送消息。",
  lineChannelDescription:
    "如要使用 LINE 与我们交谈，请使用 LINE 应用程序扫描此二维码，并给我们发送消息。",
  linkError: "试图为此渠道生成链接时发生错误。请重试。",
  linkChannelPageHeader: "同步您的对话",
  locationNotSupported: "您的浏览器不支持定位服务或已被禁用。请输入您的位置。",
  locationSecurityRestriction: "此网站无法访问您的位置。请输入您的位置。",
  locationSendingFailed: "无法发送位置",
  locationServicesDenied:
    "此网站无法访问您的位置。请在设置中允许访问或输入您的位置。",
  messageIndicatorTitlePlural: "({count}) 条新消息",
  messageIndicatorTitleSingular: "({count}) 条新消息",
  messageRelativeTimeDay: "{value} 天前",
  messageRelativeTimeHour: "{value} 小时前",
  messageRelativeTimeJustNow: "刚才",
  messageRelativeTimeMinute: "{value} 分钟前",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "已送达",
  messageSeen: "已查看",
  messageSending: "正在发送......",
  messengerChannelDescription:
    "连接您的 Facebook Messenger 帐户，以在您得到回复时收到相应通知，并通过 Facebook Messenger 继续对话。",
  newConversationButtonText: "新对话",
  notificationSettingsChannelsDescription:
    "连接到您最喜欢的消息应用程序，以同步此对话，并按照您的方式继续对话。",
  notificationSettingsChannelsTitle: "其他渠道",
  notificationSettingsConnected: "已连接",
  notificationSettingsConnectedAs: "已以 {username} 身份连接",
  prechatCaptureGreetingText: "您好！👋\n 首先，我们想多了解一些关于您的信息：",
  prechatCaptureNameLabel: "您的姓名",
  prechatCaptureNamePlaceholder: "请输入您的姓名......",
  prechatCaptureEmailLabel: "电邮",
  prechatCaptureEmailPlaceholder: "name@company.com",
  prechatCaptureConfirmationText: "谢谢！我们可以帮您什么忙？",
  prechatCaptureMailgunLinkingConfirmation:
    "一旦我们回复，您会在此处和 {email} 通过电邮收到相应通知。",
  privacyLink: "https://www.zendesk.com/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "隐私政策",
  sendButtonText: "发送",
  settingsHeaderText: "设置",
  shareLocation: "位置",
  smsBadRequestError: "我们无法用此号码进行通信。请再试一次或换个号码。",
  smsCancel: "取消",
  smsChangeNumber: "更改我的号码",
  smsChannelDescription:
    "连接您的 SMS 号码，以在您得到回复时收到相应通知，并通过 SMS 继续对话。",
  smsChannelPendingDescription:
    "在 {number} 查看您的消息，以确认您的电话号码。",
  smsContinue: "发送",
  smsInvalidNumberError: "请提交有效的电话号码。",
  smsLinkCancelled: "与 {appUserNumber} 的链接已取消。",
  smsLinkPending: "待回应",
  smsPingChannelError: "发送消息到您的号码时出错。",
  smsSendText: "给我发送短信",
  smsStartTexting: "开始发短信",
  smsTooManyRequestsError:
    "最近曾请求与该号码连接。请在 {minutes} 分钟后重试。",
  smsTooManyRequestsOneMinuteError:
    "最近曾请求与该号码连接。请在 1 分钟后重试。",
  smsUnhandledError: "出错了。请重试。",
  syncConversation: "同步对话",
  tapToRetry: "消息未送达。点击以重试。",
  tapToRetryForm: "表单未提交。点击表单中任何地方以重试。",
  telegramChannelDescription:
    "连接您的 Telegram 帐户，以在您得到回复时收到相应通知，并通过 Telegram 继续对话",
  unsupportedMessageType: "不支持的消息类型。",
  unsupportedActionType: "不支持的操作类型。",
  uploadDocument: "文件",
  uploadInvalidError: "无效的文件",
  uploadPhoto: "图像",
  uploadVirusError: "您的文件因其中检测到病毒已被拒绝",
  viberChannelDescription:
    "连接您的 Viber 帐户，以在您得到回复时收到相应通知，并通过 Viber 继续对话。如要开始，请使用 Viber 应用程序扫描此二维码。",
  viberChannelDescriptionMobile:
    "连接您的 Viber 帐户，以在您得到回复时收到相应通知，并通过 Viber 继续对话。如要开始，请安装 Viber 应用程序，点击“连接”。",
  viberQRCodeError: "提取您的 Viber 二维码时发生错误。请重试。",
  wechatChannelDescription:
    "连接您的微信帐户，以在您得到回复时收到相应通知，并通过微信继续对话。如要开始，请使用微信应用程序扫描此二维码。",
  wechatChannelDescriptionMobile:
    "连接您的微信帐户，以在您得到回复时收到相应通知，并通过微信继续对话。如要开始，请保存并在<a href='weixin://dl/scan'><u>二维码扫描仪</u></a>中上传此二维码图像 。",
  wechatQRCodeError: "提取您的微信二维码时发生错误。请重试。",
  whatsappChannelDescriptionDesktop:
    "扫描此二维码或单击下方链接，将您的帐户同步到 WhatsApp。\n 然后，发送预先填充的消息验证同步请求。（您的 {{code}}）。",
  whatsappChannelDescriptionMobile:
    "单击下方链接将您的帐户同步到 WhatsApp。\n 然后，发送预先填充的消息验证同步请求。（您的 {{code}}）。",
  whatsappLinkingError: "提取您的 WhatsApp 链接信息时发生错误。请重试。",
  zBotErrorBanner: "无法连接到 Z Bot",
  zBotErrorButtonText: "前往网络表格",
  zBotErrorMessage:
    "我们遇到了技术问题，无法为您连接到  Z Bot。如需联系我们，可随时通过我们的网络表格提交请求。",
};
