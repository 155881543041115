import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";
import ja from "./ja";
import pt from "./pt-br";
import it from "./it";
import ru from "./ru";
import ko from "./ko";
import zh from "./zh-cn";

export default {
  de,
  en,
  es,
  fr,
  pt,
  it,
  ja,
  ru,
  ko,
  zh,
};
