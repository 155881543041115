export default {
  actionPaymentCompleted: "Zahlung abgeschlossen",
  actionPaymentError:
    "Beim Verarbeiten der Karte ist ein Fehler aufgetreten. <br> Bitte versuchen Sie es erneut oder verwenden Sie eine andere Karte.",
  actionPostbackError:
    "Beim Verarbeiten Ihrer Zahlung ist ein Fehler aufgetreten Bitte versuchen Sie es erneut.",
  clickToRetry:
    "Formular nicht eingereicht. Klicken Sie auf eine beliebige Stelle im Formular, um es erneut zu versuchen.",
  clickToRetryForm:
    "Formular nicht eingereicht. Tippen Sie auf eine beliebige Stelle im Formular, um es erneut zu versuchen.",
  connectNotificationText:
    "Synchronisieren Sie diese Konversation, indem Sie eine Verbindung zu Ihrer bevorzugten Messaging-App herstellen, um die Konversation wie gewünscht fortzusetzen.",
  connectNotificationSingleText:
    "Lassen Sie sich benachrichtigen, wenn Sie eine Antwort erhalten.",
  conversationListHeaderText: "Meine Konversationen",
  conversationListPreviewAnonymousText: "Jemand",
  conversationListPreviewCarouselText: "{user} hat eine Nachricht gesendet",
  conversationListPreviewFileText: "{user} hat eine Datei gesendet",
  conversationListPreviewFormText: "{user} hat ein Formular gesendet",
  conversationListPreviewFormResponseText: "{user} hat ein Formular ausgefüllt",
  conversationListPreviewImageText: "{user} hat ein Bild gesendet",
  conversationListPreviewLocationRequestText:
    "{user} hat eine Standortanfrage gesendet",
  conversationListPreviewUserText: "Sie",
  conversationListRelativeTimeJustNow: "Gerade eben",
  conversationListRelativeTimeMinute: "vor &lt; 1 Minute",
  conversationListRelativeTimeMinutes: "vor {value} Minuten",
  conversationListRelativeTimeHour: "vor 1 Stunde",
  conversationListRelativeTimeHours: "vor {value} Stunden",
  conversationListRelativeTimeYesterday: "Gestern",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "Offline. Sie erhalten keine Nachrichten.",
  couldNotConnectRetry: "Neu verbinden...",
  couldNotConnectRetrySuccess: "Sie sind wieder online.",
  couldNotLoadConversations: "Konversationen konnten nicht geladen werden.",
  emailChangeAddress: "Meine E-Mail-Adresse ändern",
  emailDescription:
    "Um per E-Mail benachrichtigt zu werden, wenn Sie eine Antwort erhalten, geben Sie Ihre E-Mail-Adresse ein.",
  emailFieldLabel: "E-Mail-Adresse",
  emailFieldPlaceholder: "Ihre E-Mail-Adresse",
  emailFormButton: "Einreichen",
  emailLinkingErrorMessage: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  fetchHistory: "Weitere laden",
  fetchingHistory: "Verlauf wird abgerufen ...",
  fileTooLargeError: "Max. Dateigröße überschritten ({size})",
  fileTypeError: "Nicht unterstützter Dateityp.",
  formErrorInvalidEmail: "E‑Mail-Adresse ist ungültig",
  formErrorNoLongerThan: "Darf nicht mehr als ({characters}) enthalten",
  formErrorNoShorterThan:
    "Es muss aus mindestens ({characters}) Zeichen bestehen.",
  formErrorUnknown: "Das sieht nicht ganz richtig aus",
  formFieldSelectPlaceholderFallback: "Wählen Sie eine ...",
  frontendEmailChannelDescription:
    "Wenn Sie per E-Mail mit uns sprechen möchten, senden Sie einfach eine Nachricht an unsere E-Mail-Adresse. Wir antworten in Kürze:",
  headerText: "Wie können wir helfen?",
  imageClickToReload: "Klicken Sie, um das Bild neu zu laden.",
  imageClickToView: "Zum Anzeigen klicken Sie {size} Bild.",
  imagePreviewNotAvailable: "Keine Vorschau verfügbar.",
  inputPlaceholder: "Nachricht eingeben...",
  inputPlaceholderBlocked: "Füllen Sie das Formular oben aus ...",
  introAppText:
    "Sie können uns unten oder in Ihrer bevorzugten App eine Nachricht senden.",
  lineChannelDescription:
    "Wenn Sie mit uns über LINE sprechen möchten, scannen Sie diesen QR-Code mit der LINE-App und senden Sie uns eine Nachricht.",
  linkError:
    "Beim Versuch, einen Link für diesen Kanal zu generieren, ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  linkChannelPageHeader: "Synchronisieren Sie Ihre Konversation",
  locationNotSupported:
    "Ihr Browser unterstützt Ortungsdienste nicht oder wurde deaktiviert. Bitte geben Sie stattdessen Ihren Standort ein.",
  locationSecurityRestriction:
    "Diese Website kann nicht auf Ihren Standort zugreifen. Bitte geben Sie stattdessen Ihren Standort ein.",
  locationSendingFailed: "Standort konnte nicht gesendet werden",
  locationServicesDenied:
    "Diese Website kann nicht auf Ihren Standort zugreifen. Zugriff in den Einstellungen zulassen oder stattdessen Standort eingeben.",
  messageIndicatorTitlePlural: "({count}) Neue Nachrichten",
  messageIndicatorTitleSingular: "({count}) Neue Nachricht",
  messageRelativeTimeDay: "vor {value} T",
  messageRelativeTimeHour: "vor {value} h",
  messageRelativeTimeJustNow: "Gerade eben",
  messageRelativeTimeMinute: "vor {value} m",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "Zugestellt",
  messageSeen: "Gesehen",
  messageSending: "Senden",
  messengerChannelDescription:
    "Verbinden Sie Ihr Facebook Messenger-Konto, damit Sie benachrichtigt werden, wenn Sie eine Antwort erhalten, und setzen Sie die Konversation auf Facebook Messenger fort.",
  newConversationButtonText: "Neue Konversation",
  notificationSettingsChannelsDescription:
    "Synchronisieren Sie diese Konversation, indem Sie eine Verbindung zu Ihrer bevorzugten Messaging-App herstellen, um die Konversation wie gewünscht fortzusetzen.",
  notificationSettingsChannelsTitle: "Andere Kanäle",
  notificationSettingsConnected: "Verbunden",
  notificationSettingsConnectedAs: "Verbunden als {username}",
  prechatCaptureGreetingText:
    "Hallo!👋\nAls Erstes möchten wir Sie noch etwas näher kennenlernen:",
  prechatCaptureNameLabel: "Name",
  prechatCaptureNamePlaceholder: "Namen eingeben ...",
  prechatCaptureEmailLabel: "E-Mail-Adresse",
  prechatCaptureEmailPlaceholder: "name@unternehmen.com",
  prechatCaptureConfirmationText: "Vielen Dank. Womit können wir Ihnen helfen?",
  prechatCaptureMailgunLinkingConfirmation:
    "Sie werden hier und per E-Mail unter benachrichtigt {email} sobald wir antworten.",
  privacyLink: "https://www.zendesk.de/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "Datenschutzrichtlinie",
  sendButtonText: "Senden",
  settingsHeaderText: "Einstellungen",
  shareLocation: "Ort",
  smsBadRequestError:
    "Wir konnten Niemanden unter dieser Nummer erreichen. Versuchen Sie es erneut oder verwenden Sie eine andere.",
  smsCancel: "Abbrechen",
  smsChangeNumber: "Meine Nummer ändern",
  smsChannelDescription:
    "Verbinden Sie Ihre SMS-Nummer, damit Sie benachrichtigt werden, wenn Sie eine Antwort erhalten, und setzen Sie die Konversation per SMS fort.",
  smsChannelPendingDescription:
    "Überprüfen Sie Ihre Nachrichten unter {number}, um Ihre Telefonnummer zu bestätigen.",
  smsContinue: "Senden",
  smsInvalidNumberError: "Bitte geben Sie eine gültige Telefonnummer ein.",
  smsLinkCancelled: "Link zu {appUserNumber} wurde abgebrochen.",
  smsLinkPending: "Ausstehend",
  smsPingChannelError:
    "Fehler beim Senden einer Nachricht an Ihre Nummer aufgetreten.",
  smsSendText: "Senden Sie mir eine SMS",
  smsStartTexting: "Beginnen Sie mit der SMS",
  smsTooManyRequestsError:
    "Kürzlich wurde eine Verbindung für diese Nummer angefordert. Bitte versuchen Sie es in {minutes} Minuten noch einmal.",
  smsTooManyRequestsOneMinuteError:
    "Kürzlich wurde eine Verbindung für diese Nummer angefordert. Bitte versuchen Sie es in 1 Minuten noch einmal.",
  smsUnhandledError: "Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  syncConversation: "Konversation synchronisieren",
  tapToRetry: "Nachricht nicht zugestellt: Tippen, um es erneut zu versuchen.",
  tapToRetryForm:
    "Formular nicht eingereicht. Tippen Sie auf eine beliebige Stelle im Formular, um es erneut zu versuchen.",
  telegramChannelDescription:
    "Verbinden Sie Ihr Telegram-Konto, damit Sie benachrichtigt werden, wenn Sie eine Antwort erhalten, und setzen Sie die Konversation über Telegram fort",
  unsupportedMessageType: "Nicht unterstützter Nachrichtentyp.",
  unsupportedActionType: "Nicht unterstützter Aktionstyp.",
  uploadDocument: "Datei",
  uploadInvalidError: "Ungültige Datei",
  uploadPhoto: "Bild",
  uploadVirusError:
    "In Ihrer Datei wurde ein Virus gefunden, der abgewiesen wurde",
  viberChannelDescription:
    "Verbinden Sie Ihr Viber-Konto, damit Sie benachrichtigt werden, wenn Sie eine Antwort erhalten, und setzen Sie die Konversation auf Viber fort. Um zu beginnen, scannen Sie den QR-Code mit der Viber-App.",
  viberChannelDescriptionMobile:
    "Verbinden Sie Ihr Viber-Konto, damit Sie benachrichtigt werden, wenn Sie eine Antwort erhalten, und setzen Sie die Konversation auf Viber fort. Installieren Sie zuerst die Viber-App und tippen Sie auf „Verbinden“.",
  viberQRCodeError:
    "Beim Abrufen Ihres Viber-QR-Codes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  wechatChannelDescription:
    "Verbinden Sie Ihr WeChat-Konto, damit Sie benachrichtigt werden, wenn Sie eine Antwort erhalten, und setzen Sie die Konversation über WeChat fort. Scannen Sie diesen QR-Code zunächst mit der WeChat-App.",
  wechatChannelDescriptionMobile:
    "Verbinden Sie Ihr WeChat-Konto, damit Sie benachrichtigt werden, wenn Sie eine Antwort erhalten, und setzen Sie die Konversation über WeChat fort. Um loszulegen, speichern diesen QR - Code - Bild und laden Sie es in dem <a href='weixin://dl/scan'>QR-Code-Scanner</a>.",
  wechatQRCodeError:
    "Beim Abrufen Ihres WeChat-QR-Codes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  whatsappChannelDescriptionDesktop:
    "Synchronisieren Sie Ihr Konto mit WhatsApp, indem Sie den QR-Code scannen oder auf den Link unten klicken.\nSenden Sie dann die vordefinierte Nachricht, um die Synchronisationsanforderung zu bestätigen. (Ihr {{code}}).",
  whatsappChannelDescriptionMobile:
    "Klicken Sie auf den Link unten, um Ihr Konto mit WhatsApp zu synchronisieren.\nSenden Sie dann die vordefinierte Nachricht, um die Synchronisationsanforderung zu bestätigen. (Ihr {{code}}).",
  whatsappLinkingError:
    "Beim Abrufen Ihrer WhatsApp-Linkinformationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  zBotErrorBanner: "Keine Verbindung zu Z Bot",
  zBotErrorButtonText: "Zum Webformular gehen",
  zBotErrorMessage:
    "Es liegt ein technisches Problem vor und wir können Sie nicht verbinden mit Z Bot. Wenn Sie mit uns Kontakt aufnehmen möchten, steht Ihnen unser Webformular zur Verfügung.",
};
