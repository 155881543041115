export default {
  previousMessageUnavailable: "Messages précédents indisponibles",
  actionPaymentCompleted: "Paiement terminé",
  actionPaymentError:
    "Une erreur est survenue lors du traitement de la carte. <br> Réessayez ou utilisez une autre carte.",
  actionPostbackError:
    "Une erreur est survenue lors du traitement de votre action. Réessayez.",
  clickToRetry: "Message non remis. Cliquez pour réessayer.",
  clickToRetryForm:
    "Formulaire non envoyé. Cliquez n’importe où sur le formulaire pour réessayer.",
  connectNotificationText:
    "Synchronisez votre conversation et continuez à nous envoyer des messages via l’application de votre choix.",
  connectNotificationSingleText:
    "Soyez informé lorsque vous recevez une réponse.",
  conversationListHeaderText: "Mes conversations",
  conversationListPreviewAnonymousText: "Quelqu’un",
  conversationListPreviewCarouselText: "{user} a envoyé un message.",
  conversationListPreviewFileText: "{user} a envoyé un fichier",
  conversationListPreviewFormText: "{user} a envoyé un formulaire",
  conversationListPreviewFormResponseText: "{user} a rempli un formulaire",
  conversationListPreviewImageText: "{user} a envoyé une image",
  conversationListPreviewLocationRequestText:
    "{user} a envoyé une demande d’emplacement",
  conversationListPreviewUserText: "Pour vous",
  conversationListRelativeTimeJustNow: "À l’instant",
  conversationListRelativeTimeMinute: "Il y a 1 minute",
  conversationListRelativeTimeMinutes: "il y a {value} minutes",
  conversationListRelativeTimeHour: "Il y a 1 heure",
  conversationListRelativeTimeHours: "il y a {value} heures",
  conversationListRelativeTimeYesterday: "Hier",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "Hors ligne. Vous ne recevrez pas de messages.",
  couldNotConnectRetry: "Reconnexion en cours",
  couldNotLoadConversations: "Impossible de charger les conversations.",
  emailChangeAddress: "Modifier mon adresse e-mail",
  emailDescription:
    "Pour  être informé par e-mail lorsque vous recevez une réponse, saisissez votre adresse e-mail.",
  emailFieldLabel: "E-mail",
  emailFieldPlaceholder: "Votre adresse e-mail",
  emailFormButton: "Envoyer",
  emailLinkingErrorMessage: "Veuillez saisir une adresse e-mail valide.",
  fetchHistory: "Charger plus de segments",
  fetchingHistory: "Récupération de l’historique...",
  fileTooLargeError: "Taille de fichier maximale dépassée ({size})",
  fileTypeError: "Type de fichier non pris en charge.",
  formErrorInvalidEmail: "L’adresse e-mail n’est pas valide",
  formErrorNoLongerThan:
    "Ne doit pas contenir plus de ({characters}) caractères",
  formErrorNoShorterThan: "Doit contenir au moins ({characters}) caractères",
  formErrorUnknown: "Il semble que nous rencontrons un problème",
  formFieldSelectPlaceholderFallback:
    "Choisissez l’une des options suivantes...",
  frontendEmailChannelDescription:
    "Pour nous contacter par e-mail, envoyez un message à notre adresse e-mail et nous vous répondrons dans les plus brefs délais :",
  headerText: "Comment pouvons-nous vous aider ?",
  imageClickToReload: "Cliquez pour recharger l’image.",
  imageClickToView: "Cliquez pour afficher {size} image.",
  imagePreviewNotAvailable: "Pas d’aperçu disponible.",
  inputPlaceholder: "Saisissez un message...",
  inputPlaceholderBlocked: "Remplissez le formulaire ci-dessus...",
  introAppText:
    "Envoyez-nous un message ci-dessous ou à partir de l’application de votre choix.",
  lineChannelDescription:
    "Pour nous contacter via LINE, scannez ce code QR à l’aide de l’application LINE et envoyez-nous un message.",
  linkError:
    "Une erreur est survenue lors de la génération d’un lien pour ce canal. Réessayez.",
  linkChannelPageHeader: "Synchronisez votre conversation",
  locationNotSupported:
    "Les services de localisation ne sont pas pris en charge par votre navigateur ou ils ont été désactivés. Veuillez saisir votre emplacement.",
  locationSecurityRestriction:
    "Ce site web ne peut pas accéder à votre emplacement. Veuillez saisir votre emplacement.",
  locationSendingFailed: "Impossible d’envoyer l’emplacement",
  locationServicesDenied:
    "Ce site web ne peut pas accéder à votre emplacement. Autorisez l’accès dans vos paramètres ou saisissez votre emplacement.",
  messageIndicatorTitlePlural: "({count}) nouveaux messages",
  messageIndicatorTitleSingular: "({count}) nouveau message",
  messageRelativeTimeDay: "il y a {value} j",
  messageRelativeTimeHour: "il y a {value} h",
  messageRelativeTimeJustNow: "À l’instant",
  messageRelativeTimeMinute: "il y a {value} min",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "Livré",
  messageSeen: "Vu",
  messageSending: "Envoi...",
  messengerChannelDescription:
    "Connectez votre compte Facebook Messenger pour être informé lorsque vous recevez une réponse et poursuivre la conversation sur Facebook Messenger.",
  newConversationButtonText: "Nouvelle conversation",
  notificationSettingsChannelsDescription:
    "Synchronisez cette conversation en vous connectant à l’application de messagerie de votre choix pour poursuivre la conversation comme vous le souhaitez.",
  notificationSettingsChannelsTitle: "Autres canaux",
  notificationSettingsConnected: "Connecté",
  notificationSettingsConnectedAs: "Connecté en tant que {username}",
  prechatCaptureGreetingText:
    "Bonjour 👋\nPour commencer, nous aimerions en savoir un peu plus sur vous :",
  prechatCaptureNameLabel: "Votre nom",
  prechatCaptureNamePlaceholder: "Saisissez votre nom...",
  prechatCaptureEmailPlaceholder: "nom@entreprise.com",
  prechatCaptureConfirmationText: "Merci ! Que pouvons-nous faire pour vous ?",
  prechatCaptureMailgunLinkingConfirmation:
    "Vous serez informé ici et par e-mail à l’adresse {email} lorsque nous vous répondrons.",
  privacyLink: "https://www.zendesk.fr/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "Politique de confidentialité",
  sendButtonText: "Envoyer",
  settingsHeaderText: "Paramètres",
  shareLocation: "Emplacement",
  smsBadRequestError:
    "Nous n’avons pas pu communiquer avec ce numéro. Réessayez ou utilisez-en un autre.",
  smsCancel: "Annuler",
  smsChangeNumber: "Modifier mon numéro",
  smsChannelDescription:
    "Connectez votre numéro de téléphone portable pour être informé par SMS lorsque vous recevez une réponse et poursuivre la conversation par SMS.",
  smsChannelPendingDescription:
    "Consultez vos messages au {number} pour confirmer votre numéro de téléphone.",
  smsContinue: "Envoyer",
  smsInvalidNumberError: "Veuillez fournir un numéro de téléphone valide.",
  smsLinkCancelled: "Le lien vers {appUserNumber} a été annulé.",
  smsLinkPending: "En attente",
  smsPingChannelError:
    "Une erreur est survenue lors de l’envoi d’un message à votre numéro.",
  smsSendText: "Envoyez-moi un SMS",
  smsStartTexting: "Commencer à envoyer des SMS",
  smsTooManyRequestsError:
    "Une connexion a été demandée récemment pour ce numéro. Merci de réessayer dans {minutes} minutes.",
  smsTooManyRequestsOneMinuteError:
    "Une connexion a été demandée récemment pour ce numéro. Merci de réessayer dans 1 minute.",
  smsUnhandledError: "Un problème est survenu. Réessayez.",
  syncConversation: "Synchroniser la conversation",
  tapToRetry: "Message non remis. Appuyez pour réessayer.",
  tapToRetryForm:
    "Formulaire non envoyé. Appuyez n’importe où sur le formulaire pour réessayer.",
  telegramChannelDescription:
    "Connectez votre compte Telegram pour être informé lorsque vous recevez une réponse et poursuivre la conversation sur Telegram",
  unsupportedMessageType: "Type de message non pris en charge.",
  unsupportedActionType: "Type d’action non pris en charge.",
  uploadDocument: "Fichier",
  uploadInvalidError: "Fichier non valide",
  uploadPhoto: "Image",
  uploadVirusError:
    "Un virus a été détecté dans votre fichier, qui a été rejeté",
  viberChannelDescription:
    "Connectez votre compte Viber pour être informé lorsque vous recevez une réponse et poursuivre la conversation sur Viber. Pour commencer, scannez le code QR à l’aide de l’application Viber.",
  viberChannelDescriptionMobile:
    "Connectez votre compte Viber pour être informé lorsque vous recevez une réponse et poursuivre la conversation sur Viber. Pour commencer, installez l’application Viber et appuyez sur Connexion.",
  viberQRCodeError:
    "Une erreur est survenue lors de la récupération de votre code QR Viber. Réessayez.",
  wechatChannelDescription:
    "Connectez votre compte WeChat pour être informé lorsque vous recevez une réponse et poursuivre la conversation sur WeChat. Pour commencer, scannez ce code QR à l’aide de l’application WeChat.",
  wechatChannelDescriptionMobile:
    "Connectez votre compte WeChat pour être informé lorsque vous recevez une réponse et poursuivre la conversation sur WeChat. Pour commencer, enregistrez cette image de code QR et chargez-la dans le <a href='weixin://dl/scan'>scanneur de code QR</a>.",
  wechatQRCodeError:
    "Une erreur est survenue lors de la récupération de votre code QR WeChat. Réessayez.",
  whatsappChannelDescriptionDesktop:
    "Synchronisez votre compte avec WhatsApp en scannant le code QR ou en cliquant sur le lien ci-dessous.\nEnsuite, envoyez le message prérempli pour valider la demande de synchronisation. (Votre {{code}}).",
  whatsappChannelDescriptionMobile:
    "Synchronisez votre compte avec WhatsApp en cliquant sur le lien ci-dessous.\nEnsuite, envoyez le message prérempli pour valider la demande de synchronisation. (Votre {{code}}).",
  whatsappLinkingError:
    "Une erreur est survenue lors de la récupération de vos informations de liaison WhatsApp. Réessayez.",
  zBotErrorBanner: "Échec de la connexion à Z Bot",
  zBotErrorButtonText: "Accéder au formulaire Web",
  zBotErrorMessage:
    "Nous rencontrons un problème technique et ne pouvons pas vous mettre en relation avec Z Bot. Si vous avez besoin de nous contacter, vous pouvez utiliser notre formulaire Web.",
};
