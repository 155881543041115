import { errorTranslations } from '../lib/accessibility';
import botWidgetIcon from '../images/default.gif';
import { showLoadingIndicator } from '../lib/loaders';
import { opened, show, getIframeDoc, hidden } from './api';

let failover = false;

/**
 * Gets failover state value.
 * @returns {boolean} Failover state value
 */
export const getFailoverState = () => failover;

/**
 * Sets failover state value.
 * @param {boolean} toggle - Is failover
 */
export const setFailoverState = (toggle) => {
  failover = toggle;
};

/**
 * Checks if widget is opened, then creates and inserts an error banner element
 * with text.
 * @param {string} [text="Unable to connect to Z Bot"] - Text for widget error
 * banner
 */
export const showErrorBanner = (text = 'Unable to connect to Z Bot') => {
  // Timeout used to wait for opening animation to complete before manipulating
  // DOM elements
  setTimeout(() => {
    if (opened()) {
      const iframeDoc = getIframeDoc();
      if (iframeDoc) {
        const existingNumBanners =
          iframeDoc.querySelectorAll('.jwt-error').length;
        if (existingNumBanners < 1) {
          const errorTextContainer = iframeDoc.querySelector(
            '.widget-md #wrapper #header'
          );
          const errorBanner = `<div class="banner-container jwt-error error"><div class="banner-text-container">${text}</div></div>`;
          if (errorTextContainer)
            errorTextContainer.insertAdjacentHTML('afterend', errorBanner);
        }
      }
    }
  }, 100);
};

/**
 * Checks if widget is opened, then hides the error banner.
 */
export const hideErrorBanner = () => {
  // Timeout used to wait for opening animation to complete before manipulating
  // DOM elements
  setTimeout(() => {
    if (opened()) {
      const iframeDoc = getIframeDoc();
      const errorTextContainer =
        iframeDoc && iframeDoc.querySelector('.jwt-error');
      if (errorTextContainer) {
        errorTextContainer.remove();
      }
    }
  }, 100);
};

/**
 * Gets the datetime, error text translations, and the failure form url and also
 * builds the error message html elements to insert
 * @param {?Element} container - Element to be inserted into with new error
 * message element
 * @param {boolean} listView - Indicates if the current widget view is the conversations list
 * @param {{bannerText: string, buttonText: string, message: string}} translations - Text translations
 */
export const buildErrorMessage = (container, listView, translations) => {
  if (container) {
    const currentDatetime = new Date(Date.now()).toLocaleString();
    const conversationFailureText = translations.message;
    const conversationButtonFailureText = translations.buttonText;
    const conversationFailureFormUrl =
      'https://support.zendesk.com/hc/en-us/requests/new?ticket_form_id=4546346685850';
    const conversationTimestamp = `<div class="conversation-timestamp-header">${currentDatetime}</div>`;
    const conversationFrom = `<div class="from">Z Bot</div>`;
    const conversationAvatar = `<div class="msg-avatar"><img alt="Z Bot" src="${botWidgetIcon}"></div>`;
    const conversationMessageItem = `<span class="message-item message-text last-item"><span><span>${conversationFailureText}</span><br></span></span>`;
    const conversationButton = `<div class="action">
<a class="btn btn-primary" href="${conversationFailureFormUrl}" target="_blank" rel="noopener noreferrer" style="border-color: rgb(23, 73, 77); background-color: rgb(23, 73, 77); margin: 0 auto 10px auto;">${conversationButtonFailureText}</a>
</div>`;
    const conversationMessage = `<div class="msg" data-tip="${currentDatetime}" data-iscapture="true" currentitem="true"><div><div>${conversationMessageItem}${conversationButton}</div></div>`;
    const conversationRow = `<div class="row left-row">${conversationFrom}<div class="msg-wrapper">${conversationAvatar}${conversationMessage}</div><div class="clear"></div></div>`;
    const conversationListContainer = `<div class="messages-container" style="margin-bottom: 1rem;"><div class="messages">${conversationTimestamp}${conversationRow}</div></div>`;
    if (listView) {
      // TODO: Safely insert new html, innerHTML must be replaced.
      // For efficiencies sake, let's avoid a deep object copy and new element insertion and instead just reassign the param
      // eslint-disable-next-line no-param-reassign
      container.innerHTML = `<div id="conversation" style="height: 100%">${conversationListContainer}</div>`;
    } else {
      // eslint-disable-next-line no-param-reassign
      container.innerHTML = conversationListContainer;
    }
  }
};

/**
 * Activates widget failover by setting failover state and showing the widget
 * iframe, error banner, loader. Disables header and footer inputs to prevent
 * user input and lastly builds, then shows, the failover help webform.
 */
export const activateFailover = () => {
  setFailoverState(true);

  if (hidden()) show();

  const iframeDoc = getIframeDoc();
  if (iframeDoc) {
    let listView = false;
    const backHandle = iframeDoc.querySelector('.back-handle');
    const footer = iframeDoc.getElementById('footer');
    let messagesContainer = iframeDoc.querySelector('#conversation');
    if (!messagesContainer) {
      messagesContainer = iframeDoc.querySelector(
        '.conversation-list-container'
      );
      listView = true;
    }
    const zBotErrorTranslations = errorTranslations();

    showErrorBanner(zBotErrorTranslations.bannerText);
    showLoadingIndicator(false, 'conversation');

    if (backHandle)
      backHandle.style.setProperty('display', 'none', 'important');
    if (footer) footer.style.setProperty('display', 'none', 'important');

    // Replace messages container with error
    buildErrorMessage(messagesContainer, listView, zBotErrorTranslations);
  }
};
