export default {
  previousMessageUnavailable: "Предыдущие сообщения недоступны",
  actionPaymentCompleted: "Платеж выполнен",
  actionPaymentError:
    "Ошибка при обработке карты. <br> Повторите попытку или используйте другую карту.",
  actionPostbackError:
    "При обработке вашего действия произошла ошибка. Повторите попытку.",
  clickToRetry: "Сообщение не доставлено. Нажмите, чтобы повторить попытку.",
  clickToRetryForm:
    "Форма не отправлена. Щелкните в любом месте формы, чтобы повторить попытку.",
  connectNotificationText:
    "Синхронизируйте разговор и продолжайте обмениваться сообщениями через ваше любимое приложение.",
  connectNotificationSingleText: "Получайте уведомления об ответах.",
  conversationListHeaderText: "Мои разговоры",
  conversationListPreviewAnonymousText: "Кто-то",
  conversationListPreviewCarouselText: "Получено сообщение ({user})",
  conversationListPreviewFileText: "Получен файл ({user})",
  conversationListPreviewFormText: "Получена форма ({user})",
  conversationListPreviewFormResponseText:
    "Получена заполненная форма ({user})",
  conversationListPreviewImageText: "Получено изображение ({user})",
  conversationListPreviewLocationRequestText:
    "Получен запрос местоположения ({user})",
  conversationListPreviewUserText: "Вы",
  conversationListRelativeTimeJustNow: "Только что",
  conversationListRelativeTimeMinute: "1 минуту назад",
  conversationListRelativeTimeMinutes: "{value} мин. назад",
  conversationListRelativeTimeHour: "1 час назад",
  conversationListRelativeTimeHours: "{value} ч назад",
  conversationListRelativeTimeYesterday: "Вчера",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, H:mm",
  couldNotConnect: "Не в сети. Вы не будете получать сообщения.",
  couldNotConnectRetry: "Восстановление подключения...",
  couldNotConnectRetrySuccess: "Вы снова в сети!",
  couldNotLoadConversations: "Не удалось загрузить разговоры.",
  emailChangeAddress: "Изменить адрес эл. почты",
  emailDescription:
    "Чтобы получать уведомления по электронной почте при поступлении ответа, введите адрес электронной почты.",
  emailFieldLabel: "Электронная почта",
  emailFieldPlaceholder: "Ваш адрес электронной почты",
  emailFormButton: "Отправить",
  emailLinkingErrorMessage: "Укажите правильный адрес электронной почты.",
  fetchHistory: "Загрузить еще",
  fetchingHistory: "Получение истории...",
  fileTooLargeError: "Превышено ограничение размера файла ({size})",
  fileTypeError: "Неподдерживаемый тип файла.",
  formErrorInvalidEmail: "Недопустимый адрес электронной почты",
  formErrorNoLongerThan: "Максимальное число символов: {characters}",
  formErrorNoShorterThan: "Минимальное число символов: {characters}.",
  formErrorUnknown: "Возможно, здесь есть ошибка",
  formFieldSelectPlaceholderFallback: "Выберите...",
  frontendEmailChannelDescription:
    "Чтобы пообщаться с нами по электронной почте, просто напишите на наш адрес, и мы вскоре ответим:",
  headerText: "Чем мы можем помочь?",
  imageClickToReload: "Нажмите, чтобы перезагрузить изображение.",
  imageClickToView: "Нажмите, чтобы просмотреть изображение ({size}).",
  imagePreviewNotAvailable: "Просмотр недоступен.",
  inputPlaceholder: "Введите сообщение...",
  inputPlaceholderBlocked: "Заполните форму выше...",
  introAppText:
    "Напишите нам сообщение в поле внизу или в вашем любимом приложении.",
  lineChannelDescription:
    "Чтобы пообщаться с нами через LINE, отсканируйте этот QR-код с помощью приложения LINE и отправьте нам сообщение.",
  linkError: "Не удалось создать ссылку для этого канала. Повторите попытку.",
  linkChannelPageHeader: "Синхронизация разговора",
  locationNotSupported:
    "Службы определения местоположения не поддерживаются вашим браузером или отключены. Укажите местоположение.",
  locationSecurityRestriction:
    "У этого веб-сайта нет доступа к вашему местоположению. Укажите местоположение.",
  locationSendingFailed: "Не удалось отправить данные о местоположении",
  locationServicesDenied:
    "У этого веб-сайта нет доступа к вашему местоположению. Разрешите доступ в настройках или укажите местоположение.",
  messageIndicatorTitlePlural: "Новые сообщение ({count})",
  messageIndicatorTitleSingular: "Новое сообщение ({count})",
  messageRelativeTimeDay: "{value} дн. назад",
  messageRelativeTimeHour: "{value} ч назад",
  messageRelativeTimeJustNow: "Только что",
  messageRelativeTimeMinute: "{value} мин. назад",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "Доставлено",
  messageSeen: "Просмотрено",
  messageSending: "Отправка...",
  messengerChannelDescription:
    "Подключите свой аккаунт Facebook Messenger, чтобы получить уведомление об ответе и продолжить разговор в Facebook Messenger.",
  newConversationButtonText: "Новый диалог",
  notificationSettingsChannelsDescription:
    "Подключите ваш любимый мессенджер, синхронизируйте этот разговор и продолжите его в мессенджере.",
  notificationSettingsChannelsTitle: "Другие каналы",
  notificationSettingsConnected: "Подключено",
  notificationSettingsConnectedAs: "Подключено (как {username})",
  prechatCaptureGreetingText:
    "Здравствуйте! 👋\nДля начала мы хотели бы узнать о вас немного больше:",
  prechatCaptureNameLabel: "Ваше имя",
  prechatCaptureNamePlaceholder: "Введите свое имя...",
  prechatCaptureEmailLabel: "Электронная почта",
  prechatCaptureEmailPlaceholder: "name@company.com",
  prechatCaptureConfirmationText: "Спасибо! Чем мы можем вам помочь?",
  prechatCaptureMailgunLinkingConfirmation:
    "Вы получите уведомление об ответе здесь и по электронной почте на адрес {email}.",
  privacyLink: "https://www.zendesk.com/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "политика конфиденциальности",
  sendButtonText: "Отправить",
  settingsHeaderText: "Настройки",
  shareLocation: "Местоположение",
  smsBadRequestError:
    "Не удалось связаться с этим номером. Попробуйте еще раз или используйте другой.",
  smsCancel: "Отмена",
  smsChangeNumber: "Сменить номер",
  smsChannelDescription:
    "Подключите свой номер телефона, чтобы получить уведомление об ответе и продолжить разговор по SMS.",
  smsChannelPendingDescription:
    "Дождитесь сообщения на номер {number} и подтвердите номер телефона.",
  smsContinue: "Отправить",
  smsInvalidNumberError: "Укажите правильный номер телефона.",
  smsLinkCancelled: "Ссылка на {appUserNumber} отменена.",
  smsLinkPending: "Ожидание",
  smsPingChannelError: "Не удалось отправить сообщение на ваш номер.",
  smsSendText: "Отправить SMS",
  smsStartTexting: "Начните вводить сообщение",
  smsTooManyRequestsError:
    "Запрос на подключение этого номера был недавно отправлен. Повторите попытку через {minutes} мин.",
  smsTooManyRequestsOneMinuteError:
    "Запрос на подключение этого номера был недавно отправлен. Повторите попытку через 1 минуту.",
  smsUnhandledError: "Возникла неполадка. Повторите попытку.",
  syncConversation: "Синхронизировать разговор",
  tapToRetry: "Сообщение не доставлено. Нажмите, чтобы повторить попытку.",
  tapToRetryForm:
    "Форма не отправлена. Нажмите в любом месте формы, чтобы повторить попытку.",
  telegramChannelDescription:
    "Подключите свой аккаунт Telegram, чтобы получить уведомление об ответе и продолжить разговор в Telegram.",
  unsupportedMessageType: "Неподдерживаемый тип сообщения.",
  unsupportedActionType: "Неподдерживаемый тип действия.",
  uploadDocument: "Файл",
  uploadInvalidError: "Недопустимый файл",
  uploadPhoto: "Изображение",
  uploadVirusError: "Файл был отклонен, так как он содержит вирус.",
  viberChannelDescription:
    "Подключите свой аккаунт Viber, чтобы получить уведомление об ответе и продолжить разговор в Viber. Для начала отсканируйте QR-код в приложении Viber.",
  viberChannelDescriptionMobile:
    "Подключите свой аккаунт Viber, чтобы получить уведомление об ответе и продолжить разговор в Viber. Для начала установите приложение Viber и нажмите «Подключить».",
  viberQRCodeError: "Ошибка при получении QR-кода Viber. Повторите попытку.",
  wechatChannelDescription:
    "Подключите свой аккаунт WeChat, чтобы получить уведомление об ответе и продолжить разговор в WeChat. Для начала отсканируйте этот QR-код в приложении WeChat.",
  wechatChannelDescriptionMobile:
    "Подключите свой аккаунт WeChat, чтобы получить уведомление об ответе и продолжить разговор в WeChat. Для начала сохраните изображение этого QR-кода и отправьте его в <a href='weixin://dl/scan'>сканер QR-кодов</a>.",
  wechatQRCodeError: "Ошибка при получении QR-кода WeChat. Повторите попытку.",
  whatsappChannelDescriptionDesktop:
    "Синхронизируйте свой аккаунт с WhatsApp, отсканировав QR-код или перейдя по ссылке ниже.\nЗатем отправьте подготовленное сообщение, чтобы подтвердить запрос на синхронизацию. (Ваш {{code}}).",
  whatsappChannelDescriptionMobile:
    "Синхронизируйте свой аккаунт с WhatsApp, перейдя по ссылке ниже.\nЗатем отправьте подготовленное сообщение, чтобы подтвердить запрос на синхронизацию. (Ваш {{code}}).",
  whatsappLinkingError:
    "Ошибка при получении информации о привязке к WhatsApp. Повторите попытку.",
  zBotErrorBanner: "Невозможно подключиться к Z Bot",
  zBotErrorButtonText: "Перейти к веб-форме",
  zBotErrorMessage:
    "Возникла техническая проблема, вы не можете подключиться к Z Bot. Если вы хотите связаться с нами, заполните нашу веб-форму.",
};
