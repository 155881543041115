export default {
  previousMessageUnavailable: "이전 메시지를 사용할 수 없음",
  actionPaymentCompleted: "결제 완료",
  actionPaymentError:
    "카드 처리 중 오류가 발생했습니다. <br> 다시 시도하거나 다른 카드를 사용하세요.",
  actionPostbackError:
    "작업을 처리하는 동안 오류가 발생했습니다. 다시 시도하세요.",
  clickToRetry: "메시지가 전송되지 않았습니다. 다시 시도하려면 클릭하세요.",
  clickToRetryForm:
    "양식이 제출되지 않았습니다. 다시 시도하려면 양식의 아무 곳이나 클릭합니다.",
  connectNotificationText:
    "대화를 동기화하고 즐겨 이용하는 앱을 통해 계속 메시지를 보내세요.",
  connectNotificationSingleText: "답장을 받으면 알림을 받습니다.",
  conversationListHeaderText: "내 대화",
  conversationListPreviewAnonymousText: "다른 사람",
  conversationListPreviewCarouselText: "{user}님이 메시지를 보냈습니다",
  conversationListPreviewFileText: "{user}님이 파일을 보냈습니다",
  conversationListPreviewFormText: "{user}님이 양식을 보냈습니다",
  conversationListPreviewFormResponseText: "{user}님이 양식을 작성했습니다",
  conversationListPreviewImageText: "{user}님이 이미지를 보냈습니다",
  conversationListPreviewLocationRequestText:
    "{user}님이 위치 요청을 보냈습니다",
  conversationListPreviewUserText: "귀하",
  conversationListRelativeTimeJustNow: "방금",
  conversationListRelativeTimeMinute: "1분 전",
  conversationListRelativeTimeMinutes: "{value}분 전",
  conversationListRelativeTimeHour: "1시간 전",
  conversationListRelativeTimeHours: "{value}시간 전",
  conversationListRelativeTimeYesterday: "어제",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "오프라인. 메시지를 받지 않게 됩니다.",
  couldNotConnectRetry: "다시 연결 중...",
  couldNotConnectRetrySuccess: "다시 온라인 상태입니다!",
  couldNotLoadConversations: "대화를 로드할 수 없습니다.",
  emailChangeAddress: "내 이메일 변경",
  emailDescription:
    "답장을 받을 때 이메일 알림을 받으려면 이메일 주소를 입력하세요.",
  emailFieldLabel: "이메일",
  emailFieldLabelPlaceholder: "내 이메일 주소",
  emailFormButton: "제출 사용하기",
  emailLinkingErrorMessage: "올바른 이메일 주소를 제출하세요.",
  fetchHistory: "더 로드",
  fetchingHistory: "기록 검색 중 ...",
  fileTooLargeError: "최대 파일 크기 제한을 초과함({size})",
  fileTypeError: "지원되지 않는 파일 유형입니다.",
  formErrorInvalidEmail: "이메일이 올바르지 않습니다.",
  formErrorNoLongerThan: "최대 ({characters})자 이하여야 합니다.",
  formErrorNoShorterThan: "최소 ({characters})자 이상이어야 합니다.",
  formErrorUnknown: "잘못된 것 같습니다",
  formFieldSelectPlaceholderFallback: "하나를 선택하세요...",
  frontendEmailChannelDescription:
    "이메일을 통해 저희 이메일 주소로 메시지를 보내 의견을 전달해 주시면 곧 답장해 드리겠습니다.",
  headerText: "어떻게 도와 드릴까요?",
  imageClickToReload: "이미지를 다시 로드하려면 클릭하세요.",
  imageClickToView: "{size} 이미지를 보려면 클릭하세요.",
  imagePreviewNotAvailable: "미리 보기를 사용할 수 없음.",
  inputPlaceholder: "메시지를 입력하세요.",
  inputPlaceholderBlocked: "위의 양식을 작성하세요.",
  introAppText: "아래에서 또는 즐겨 사용하는 앱에서 메시지를 보내세요.",
  lineChannelDescription:
    "LINE을 사용해 대화하려면 LINE 앱으로 이 QR 코드를 스캔하여 메시지를 보내세요.",
  linkError:
    "이 채널에 대한 링크 생성 시도 중 오류가 발생했습니다. 다시 시도하세요.",
  linkChannelPageHeader: "대화 동기화",
  locationNotSupported:
    "브라우저가 위치 서비스를 지원하지 않거나 사용 중지되었습니다. 대신 위치를 입력하세요.",
  locationSecurityRestriction:
    "이 웹 사이트는 사용자의 위치에 액세스할 수 없습니다. 대신 위치를 입력하세요.",
  locationSendingFailed: "위치를 보낼 수 없습니다.",
  locationServicesDenied:
    "이 웹 사이트는 사용자의 위치에 액세스할 수 없습니다. 설정에서 액세스를 허용하거나 위치를 입력하세요.",
  messageIndicatorTitlePlural: "새 메시지 ({count})개",
  messageIndicatorTitleSingular: "새 메시지 ({count})개",
  messageRelativeTimeDay: "{value}일 전",
  messageRelativeTimeHour: "{value}시간 전",
  messageRelativeTimeJustNow: "방금",
  messageRelativeTimeMinute: "{value}분 전",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "전달됨",
  messageSeen: "상대방이 봄",
  messageSending: "보내는 중...",
  messengerChannelDescription:
    "Facebook Messenger 계정을 연결하여 답장을 받을 때 알림을 받고 Facebook Messenger에서 대화를 계속하세요.",
  newConversationButtonText: "새 대화",
  notificationSettingsChannelsDescription:
    "즐겨 이용하는 메시징 앱에 연결하여 이 대화를 동기화하고 원하는 방식으로 대화를 계속하세요.",
  notificationSettingsChannelsTitle: "기타 채널",
  notificationSettingsConnected: "연결됨",
  notificationSettingsConnectedAs: "{username}(으)로 연결된 앱",
  prechatCaptureGreetingText:
    "안녕하세요 👋\n먼저 귀하에 대해 자세히 알고 싶습니다.",
  prechatCaptureNameLabel: "이름",
  prechatCaptureNamePlaceholder: "이름을 입력하세요...",
  prechatCaptureEmailLabel: "이메일",
  prechatCaptureEmailPlaceholder: "name@company.com",
  prechatCaptureConfirmationText: "감사합니다! 무엇을 도와드릴까요?",
  prechatCaptureMailgunLinkingConfirmation:
    "답장을 받으면 여기와 {email}에서 이메일로 알림이 전송됩니다.",
  privacyLink: "https://www.zendesk.kr/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "개인정보 처리방침",
  sendButtonText: "보내기",
  settingsHeaderText: "설정",
  shareLocation: "위치",
  smsBadRequestError:
    "이 번호로 통신할 수 없습니다. 다시 시도하거나 다른 것을 사용하세요.",
  smsCancel: "취소",
  smsChangeNumber: "내 번호 변경",
  smsChannelDescription:
    "답장을 받을 때 알림을 받으려면 SMS 번호를 연결하고 SMS에서 대화를 계속하세요.",
  smsChannelPendingDescription:
    "전화 번호를 확인하여 {number} 에서 메시지를 확인하세요.",
  smsContinue: "보내기",
  smsInvalidNumberError: "올바른 전화 번호를 제출하세요.",
  smsLinkCancelled: "{appUserNumber}에 대한 링크가 취소되었습니다.",
  smsLinkPending: "보류",
  smsPingChannelError: "번호로 메시지를 보내는 중 오류가 발생했습니다.",
  smsSendText: "나에게 문자 메시지 보내기",
  smsStartTexting: "문자 메시지 시작",
  smsTooManyRequestsError:
    "최근에 해당 번호에 대한 연결을 요청했습니다. {minutes}분 후에 다시 시도하세요.",
  smsTooManyRequestsOneMinuteError:
    "최근에 해당 번호에 대한 연결을 요청했습니다. 1분 후 다시 시도하세요.",
  smsUnhandledError: "문제가 발생했습니다. 다시 시도하세요.",
  syncConversation: "대화 동기화",
  tapToRetry: "메시지가 전송되지 않았습니다. 다시 시도하려면 탭하세요.",
  tapToRetryForm:
    "양식이 제출되지 않았습니다. 다시 시도하려면 양식의 아무 곳이나 탭합니다.",
  telegramChannelDescription:
    "Telegram 계정을 연결하여 답장을 받을 때 알림을 받고 Telegram에서 대화를 계속하세요.",
  unsupportedMessageType: "지원되지 않는 메시지 유형입니다.",
  unsupportedActionType: "지원되지 않는 작업 유형입니다.",
  uploadDocument: "파일",
  uploadInvalidError: "올바르지 않은 파일",
  uploadPhoto: "이미지",
  uploadVirusError: "파일에서 바이러스가 발견되어 거부되었습니다.",
  viberChannelDescription:
    "Viber 계정을 연결하여 답장을 받을 때 알림을 받고 Viber에서 대화를 계속하세요. 시작하려면, Viber 앱을 사용하여 QR 코드를 스캔하세요.",
  viberChannelDescriptionMobile:
    "Viber 계정을 연결하여 답장을 받을 때 알림을 받고 Viber에서 대화를 계속하세요. 시작하려면 Viber 앱을 설치하고 연결을 탭하세요.",
  viberQRCodeError:
    "Viber QR 코드를 가져오는 동안 오류가 발생했습니다. 다시 시도하세요.",
  wechatChannelDescription:
    "WeChat 계정을 연결하여 답장을 받을 때 알림을 받고 WeChat에서 대화를 계속하세요. 시작하려면, WeChat 앱을 사용하여 QR 코드를 스캔하세요.",
  wechatChannelDescriptionMobile:
    "WeChat 계정을 연결하여 답장을 받을 때 알림을 받고 WeChat에서 대화를 계속하세요. 시작하려면, 이 QR 코드 이미지를 저장라고 <a href='weixin://dl/scan'>QR 코드 스캐너</a>에 업로드하세요.",
  wechatQRCodeError:
    "WeChat QR 코드를 가져오는 동안 오류가 발생했습니다. 다시 시도하세요.",
  whatsappChannelDescriptionDesktop:
    "QR 코드를 스캔하거나 아래 링크를 클릭하여 계정을 WhatsApp에 동기화하세요. \n그 다음 미리 채워진 메시지를 보내 동기화 요청의 유효성을 검사하세요. (귀하의 {{code}}).",
  whatsappChannelDescriptionMobile:
    "아래 링크를 클릭하여 계정을 WhatsApp에 동기화하세요. \n그 다음 미리 채워진 메시지를 보내 동기화 요청의 유효성을 검사하세요. (귀하의 {{code}}).",
  whatsappLinkingError:
    "WhatsApp 링크 정보를 가져오는 중 오류가 발생했습니다. 다시 시도하세요.",
  zBotErrorBanner: "Z봇에 연결할 수 Z Bot",
  zBotErrorButtonText: "웹 양식으로 이동하세요",
  zBotErrorMessage:
    "기술적인 문제가 발생하여 Z Bot 에 연결할 수 없습니다 문의가 필요한 경우 웹 양식을 통해 요청을 접수할 수 있습니다.",
};
