import { initialize } from './initializers';
import * as apiMethods from './api';

/**
 * Constructs Z2SuncoWidget class with options passed, attaches options to class
 * and initializes widget
 */
export default class Z2SunCoWidget {
  static version = process.env.WIDGET_VERSION;

  constructor(options = {}) {
    this.initialOptions = options;
    Z2SunCoWidget.initialOptions = options;
    Object.keys(apiMethods).forEach((method) => {
      this[method] = apiMethods[method].bind(this);
    });
    initialize(options);
  }
}

// Ensure the class definition is accessible to the product window.
window.Z2SunCoWidget = Z2SunCoWidget;
