import debounce from 'lodash/debounce';
import { getSessionAuth } from '../scripts/auth';
import { checkForLocale } from './accessibility';
import { getConversationsCountOnWidgetLoad } from './helpers';

const CONVERSATION_METADATA_FRESHNESS_PROPS = Object.freeze([
  'categoryId',
  'conversationsCountOnWidgetLoad',
  'href',
  'inProduct',
  'inShoppingCart',
  'lang',
  'openWidgetToNewConversation',
  'productInstanceJwt',
  'referrer',
  'specificIntent',
  'timezone',
  'userAgent',
  'version',
  'widgetInput1',
  'widgetInput2',
  'widgetInput3',
  'widgetInput4',
  'widgetInput5',
]);

const USER_METADATA_FRESHNESS_PROPS = Object.freeze([
  'href',
  'inProduct',
  'inShoppingCart',
  'lang',
  'referrer',
]);

/**
 * Gets the id and metadata from the displayed conversation, if there's no
 * metadata or a mismatch with the current metadata paramter, then the
 * conversation is stale.
 * @param {{categoryId:string, href: string, inProduct: boolean, inShoppingCart:
 * boolean, lang: string, openWidgetToNewConversation: boolean,
 * productInstanceJwt: string, referrer: string, specificIntent: string,
 * userAgent: string, version: string|undefined, widgetInputs:{widgetInput1:
 * string, widgetInput2: string, widgetInput3: string, widgetInput4: string,
 * widgetInput5: string}}} currentMetadata - Current conversation metadata
 * @returns {string} Stale conversation id or empty string
 */
const getStaleConversationId = (currentMetadata) => {
  const { id, metadata } = window.Smooch.getDisplayedConversation() || {};
  let staleConversationId = '';
  // Check metadata freshness
  const isStale =
    !metadata ||
    (id &&
      !!CONVERSATION_METADATA_FRESHNESS_PROPS.find(
        (prop) => currentMetadata[prop] !== metadata[prop]
      ));
  if (!metadata || (isStale && metadata && !metadata.suppressUpdates)) {
    staleConversationId = id;
  }
  return staleConversationId;
};

/**
 * Gets the id and metadata from the current user, if there's no metadata or a
 * mismatch with the current metadata paramter, then the user is stale.
 * @param {{href: string, inProduct: boolean, inShoppingCart: boolean, lang:
 * string, referrer: string}} currentMetadata - Current user metadata
 * @returns {string} Stale user id or empty string
 */
const getStaleUserId = (currentMetadata) => {
  const { id, metadata } = window.Smooch.getUser() || {};
  let staleUserId = '';
  // Check metadata freshness
  const isStale =
    !metadata ||
    (id &&
      !!USER_METADATA_FRESHNESS_PROPS.find(
        (prop) => currentMetadata[prop] !== metadata[prop]
      ));
  if (!metadata || (isStale && metadata && !metadata.suppressUpdates)) {
    staleUserId = id;
  }
  return staleUserId;
};

/**
 * Debounces to update the conversation metadata every 1/2 second.
 */
const debouncedUpdateConversationMetadata = debounce((id, metadata) => {
  window.Smooch.updateConversation(id, { metadata });
}, 500);

/**
 * Debounces to update the user metadata every 1/2 second.
 */
const debouncedUpdateUserProfile = debounce((metadata) => {
  window.Smooch.updateUser({ metadata });
}, 500);

/**
 * Gets convesation metadata with the current product instance jwt from the
 * session authentication, version data, locale, and additonal window data.
 * @param {boolean} inProduct - In product implementation
 * @param {boolean} inShoppingCart - In shopping cart implementation
 * @param {?string} locale - Conversation locale
 * @param {boolean} openWidgetToNewConversation - Open widget to new
 * conversation automatically
 * @param {?string} specificIntent - Conversation's specific intent
 * @param {{widgetInput1: ?string, widgetInput2: ?string, widgetInput3: ?string,
 * widgetInput4: ?string, widgetInput5: ?string}} widgetInputs - Additional
 * conversation metadata inputs
 * @returns {{categoryId:string, href: string, inProduct: boolean,
 * inShoppingCart: boolean, lang: string, openWidgetToNewConversation: boolean,
 * productInstanceJwt: string, referrer: string, specificIntent: string|null,
 * userAgent: string, version: string|undefined, widgetInputs:{widgetInput1:
 * ?string, widgetInput2: ?string, widgetInput3: ?string, widgetInput4: ?string,
 * widgetInput5: ?string}}} Conversation metadata
 */
export const getConversationMetadata = (
  inProduct,
  inShoppingCart,
  locale,
  openWidgetToNewConversation,
  specificIntent,
  widgetInputs
) => {
  const { productInstanceJwt } = getSessionAuth();
  return {
    conversationsCountOnWidgetLoad: getConversationsCountOnWidgetLoad(),
    categoryId:
      window.Modules &&
      window.Modules.config &&
      window.Modules.config.currentCategoryID,
    href: window.location.href,
    inProduct,
    inShoppingCart,
    lang: checkForLocale(locale),
    openWidgetToNewConversation,
    productInstanceJwt,
    referrer: window.document.referrer,
    specificIntent,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userAgent: window.navigator.userAgent,
    version: process.env.WIDGET_VERSION,
    ...widgetInputs,
  };
};

/**
 * Gets user metadata with the current locale and additonal window data.
 * @param {boolean} inProduct - In product implementation
 * @param {boolean} inShoppingCart - In shopping cart implementation
 * @param {?string} locale - User locale
 * @returns {{href: string, inProduct: boolean, inShoppingCart: boolean, lang:
 * string, referrer: string}} User metadata
 */
export const getUserMetadata = (inProduct, inShoppingCart, locale) => ({
  href: window.location.href,
  inProduct,
  inShoppingCart,
  lang: checkForLocale(locale),
  referrer: window.document.referrer,
});

/**
 * Updates the conversation and/or user metadata with the given parameters when
 * there is a displayed conversation and the conversation and/or user are stale.
 * @param {{inProduct: boolean, inShoppingCart: boolean, locale: ?string,
 * openWidgetToNewConversation: boolean, specificIntent: ?string, widgetInputs:
 * {widgetInput1: ?string, widgetInput2: ?string, widgetInput3: ?string,
 * widgetInput4: ?string, widgetInput5: ?string}}} conversationMetadata - Latest
 * conversation metadata
 */
export const updateConversationMetadata = ({
  inProduct = false,
  inShoppingCart = false,
  locale = null,
  openWidgetToNewConversation = false,
  specificIntent = null,
  widgetInputs = {
    widgetInput1: null,
    widgetInput2: null,
    widgetInput3: null,
    widgetInput4: null,
    widgetInput5: null,
  },
}) => {
  const conversationMetadata = getConversationMetadata(
    inProduct,
    inShoppingCart,
    locale,
    openWidgetToNewConversation,
    specificIntent,
    widgetInputs
  );
  const userMetadata = getUserMetadata(inProduct, inShoppingCart, locale);

  // When the conversation is displayed, check if conversation and/or user
  // metadata are stale
  if (window.Smooch && window.Smooch.getDisplayedConversation) {
    const id = getStaleConversationId(conversationMetadata);
    const userId = getStaleUserId(userMetadata);

    if (id) debouncedUpdateConversationMetadata(id, conversationMetadata);
    if (userId) debouncedUpdateUserProfile(userMetadata);
  }
};
