const LOG_PREFIX = 'Z2 SunCo Widget |';

/**
 * Handles argument creation for console messages.
 * @param {Error} data - Error data object
 * @param {string} [message="Message missing!"] - Error message
 * @returns {(string | Error)[]} List with message and data or just message
 */
const getArgs = (data, message = 'Message missing!') => {
  const logMessage = `${LOG_PREFIX} ${message}`;
  const args = data ? [logMessage, data] : [logMessage];
  return args;
};

/**
 * Logs info to console.
 * @param {string} message - Info message
 * @param {Error} data - Info data object
 */
export const info = (data, message) => {
  console.info.apply(null, getArgs(message, data));
};

/**
 * Logs warning to console.
 * @param {string} message - Warning message
 * @param {Error} data - Warning data object
 */
export const warn = (data, message) => {
  console.warn.apply(null, getArgs(message, data));
};

/**
 * Logs error to console.
 * @param {string} message - Error message
 * @param {Error} data - Error data object
 */
export const error = (data, message) => {
  console.error.apply(null, getArgs(message, data));
};
