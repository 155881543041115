/* eslint-disable */

const embedFunction = function (o, p, s, e, c) {
  let i;
  let a;
  let h;
  const u = [];
  const d = [];
  function t() {
    const t = 'You must provide a supported major version.';
    try {
      if (!c) throw new Error(t);
      let e;
      const n = 'https://cdn.smooch.io/';
      const r = 'smooch';
      if (
        (e =
          typeof this.response === 'string'
            ? JSON.parse(this.response)
            : this.response).url
      ) {
        const o = p.getElementsByTagName('script')[0];
        const s = p.createElement('script');
        s.async = !0;
        const i = c.match(/([0-9]+).?([0-9]+)?.?([0-9]+)?/);
        const a = i && i[1];
        if (i && i[3]) s.src = `${n + r}.${c}.min.js`;
        else {
          if (!(a >= 4 && e[`v${a}`])) throw new Error(t);
          s.src = e[`v${a}`];
        }
        o.parentNode.insertBefore(s, o);
      }
    } catch (e) {
      e.message === t && console.error(e);
    }
  }
  (o[s] = {
    init() {
      i = arguments;
      var t = {
        then(e) {
          return d.push({ type: 't', next: e }), t;
        },
        catch(e) {
          return d.push({ type: 'c', next: e }), t;
        },
      };
      return t;
    },
    on() {
      u.push(arguments);
    },
    render() {
      a = arguments;
    },
    destroy() {
      h = arguments;
    },
  }),
    (o.__onWebMessengerHostReady__ = function (e) {
      if ((delete o.__onWebMessengerHostReady__, (o[s] = e), i))
        for (var t = e.init.apply(e, i), n = 0; n < d.length; n++) {
          const r = d[n];
          t = r.type === 't' ? t.then(r.next) : t.catch(r.next);
        }
      a && e.render.apply(e, a), h && e.destroy.apply(e, h);
      for (n = 0; n < u.length; n++) e.on.apply(e, u[n]);
    });
  const n = new XMLHttpRequest();
  n.addEventListener('load', t),
    n.open('GET', `https://${e}.webloader.smooch.io/`, !0),
    (n.responseType = 'json'),
    n.send();
};

export const configureEmbed = (integrationId) => {
  return `!(${embedFunction.toString()})(window, document, "Smooch", "${integrationId}", "5")`;
};
