// Determine environment
export const isDevEnv = ['z3nnorthwoods', 'zd-dev', 'scooter', 'localhost'].find((hint) =>
  window.location.origin.includes(hint)
);
export const isStagingEnv = [
  'z3n-ultimate-poc',
  'zendesk-staging',
  'cloudhatchery',
  'zopim.org',
].find((hint) => window.location.origin.includes(hint));

/**
 * Gets the SunCo integrationId for different environments
 * @param {?string} integrationId - Optional passed in integrationId
 * @returns {string} Determined integrationId
 */
export const getIntegrationId = (integrationId) => {
  let envIntegrationId = integrationId;
  if (envIntegrationId || isStagingEnv) {
    envIntegrationId = '5ffca45f1ae3c2000ccad0a6';
  } else if (isDevEnv) {
    envIntegrationId = '5faaa1d86eaceb000c1c8d76';
  } else {
    envIntegrationId = '6054c95a757a0200d2deeee0';
  }
  return envIntegrationId;
};
