export default {
  previousMessageUnavailable: "Messaggi precedenti non disponibili",
  actionPaymentCompleted: "Pagamento completato",
  actionPaymentError:
    "Si è verificato un errore durante l’elaborazione della carta. <br> Riprova o usa una carta diversa.",
  actionPostbackError:
    "Si è verificato un errore durante l’elaborazione dell’azione. Riprova.",
  clickToRetry: "Messaggio non consegnato. Fai clic per riprovare.",
  clickToRetryForm:
    "Modulo non inviato. Fai clic in un punto qualsiasi del modulo per riprovare.",
  connectNotificationText:
    "Sincronizza la conversazione e continua a inviarci messaggi tramite la tua app preferita.",
  connectNotificationSingleText:
    "Ricevi una notifica quando ricevi una risposta.",
  conversationListHeaderText: "Le mie conversazioni",
  conversationListPreviewAnonymousText: "Qualcuno",
  conversationListPreviewCarouselText: "{user} ha inviato un messaggio",
  conversationListPreviewFileText: "{user} ha inviato un file",
  conversationListPreviewFormText: "{user} ha inviato un modulo",
  conversationListPreviewFormResponseText: "{user} ha compilato un modulo",
  conversationListPreviewImageText: "{user} ha inviato un'immagine",
  conversationListPreviewLocationRequestText:
    "{user} ha inviato una richiesta di posizione",
  conversationListPreviewUserText: "Tu",
  conversationListRelativeTimeJustNow: "Proprio adesso",
  conversationListRelativeTimeMinute: "1 minuto fa",
  conversationListRelativeTimeMinutes: "{value} minuti fa",
  conversationListRelativeTimeHour: "1 ora fa",
  conversationListRelativeTimeHours: "{value} ore fa",
  conversationListRelativeTimeYesterday: "Ieri",
  conversationListTimestampFormat: "MM/DD/YY",
  conversationTimestampHeaderFormat: "MMMM D YYYY, h:mm A",
  couldNotConnect: "Offline. Non riceverai messaggi.",
  couldNotConnectRetry: "Riconnessione in corso...",
  couldNotConnectRetrySuccess: "Sei di nuovo online.",
  couldNotLoadConversations: "Impossibile caricare le conversazioni.",
  emailChangeAddress: "Cambia la mia email",
  emailDescription:
    "Per ricevere una notifica via email quando ricevi una risposta, inserisci il tuo indirizzo e-mail.",
  emailFieldLabel: "Email",
  emailFieldPlaceholder: "Il tuo indirizzo email",
  emailFormButton: "Invia",
  emailLinkingErrorMessage: "Invia un indirizzo email valido.",
  fetchHistory: "Carica altro",
  fetchingHistory: "Recupero cronologia in corso...",
  fileTooLargeError: "Superato il limite massimo di dimensioni file ({size})",
  fileTypeError: "Tipo di file non supportato.",
  formErrorInvalidEmail: "Indirizzo email non valido",
  formErrorNoLongerThan: "Non deve contenere più di ({characters}) caratteri",
  formErrorNoShorterThan: "Deve contenere almeno ({characters}) caratteri",
  formErrorUnknown: "Non sembra corretto",
  formFieldSelectPlaceholderFallback: "Scegline uno ...",
  frontendEmailChannelDescription:
    "Per parlare con noi tramite email, invia un messaggio al nostro indirizzo email e ti risponderemo a breve:",
  headerText: "Come possiamo aiutarti?",
  imageClickToReload: "Fai clic per ricaricare l'immagine.",
  imageClickToView: "Fai clic per visualizzare {size} immagine.",
  imagePreviewNotAvailable: "Anteprima non disponibile.",
  inputPlaceholder: "Digita un messaggio...",
  inputPlaceholderBlocked: "Compila il modulo qui sopra...",
  introAppText: "Inviaci un messaggio qui sotto o dalla tua app preferita.",
  lineChannelDescription:
    "Per parlare con noi usando LINE, scansiona questo codice QR usando l'app LINE e inviaci un messaggio.",
  linkError:
    "Si è verificato un errore durante il tentativo di generare un link per questo canale. Riprova.",
  linkChannelPageHeader: "Sincronizza la conversazione",
  locationNotSupported:
    "Il browser in uso non supporta i servizi di localizzazione o è stato disabilitato. Digita la tua località.",
  locationSecurityRestriction:
    "Questo sito Web non può accedere alla tua posizione. Digita la tua località.",
  locationSendingFailed: "Impossibile inviare la posizione",
  locationServicesDenied:
    "Questo sito Web non può accedere alla tua posizione. Consenti l’accesso nelle impostazioni o digita la tua posizione.",
  messageIndicatorTitlePlural: "({count}) Nuovi messaggi",
  messageIndicatorTitleSingular: "({count}) Nuovo messaggio",
  messageRelativeTimeDay: "{value}g fa",
  messageRelativeTimeHour: "{value}h fa",
  messageRelativeTimeJustNow: "Proprio adesso",
  messageRelativeTimeMinute: "{value}m fa",
  messageTimestampFormat: "h:mm A",
  messageDelivered: "Consegnato",
  messageSeen: "Visto",
  messageSending: "Invio in corso...",
  messengerChannelDescription:
    "Collega il tuo account Facebook Messenger per ricevere una notifica quando ricevi una risposta e continuare la conversazione su Facebook Messenger.",
  newConversationButtonText: "Nuova conversazione",
  notificationSettingsChannelsDescription:
    "Sincronizza questa conversazione collegandoti alla tua app di messaggistica preferita per continuare la conversazione come preferisci.",
  notificationSettingsChannelsTitle: "Altri canali",
  notificationSettingsConnected: "Connesso",
  notificationSettingsConnectedAs: "Collegato come {username}",
  prechatCaptureGreetingText:
    "Salve 👋\nPer cominciare, vorremmo sapere qualcosa in più su di te:",
  prechatCaptureNameLabel: "Il tuo nome",
  prechatCaptureNamePlaceholder: "Digita il tuo nome ...",
  prechatCaptureEmailLabel: "Email",
  prechatCaptureEmailPlaceholder: "nome@azienda.com",
  prechatCaptureConfirmationText: "Grazie! Come possiamo aiutarti?",
  prechatCaptureMailgunLinkingConfirmation:
    "Riceverai una notifica qui e via email all’indirizzo {email} una volta che rispondiamo.",
  privacyLink: "https://www.zendesk.com/it/company/agreements-and-terms/privacy-notice/",
  privacyPolicy: "Informativa sulla privacy",
  sendButtonText: "Invia",
  settingsHeaderText: "Impostazioni",
  shareLocation: "Ubicazione",
  smsBadRequestError:
    "Impossibile comunicare con questo numero. Riprova o usane un altro.",
  smsCancel: "Annulla",
  smsChangeNumber: "Cambia il mio numero",
  smsChannelDescription:
    "Collega il tuo numero SMS per ricevere una notifica quando ricevi una risposta e continuare la conversazione tramite SMS.",
  smsChannelPendingDescription:
    "Controlla i tuoi messaggi all’indirizzo {number} per confermare il numero di telefono.",
  smsContinue: "Invia",
  smsInvalidNumberError: "Invia un numero di telefono valido.",
  smsLinkCancelled: "Il link a {appUserNumber} è stato annullato.",
  smsLinkPending: "In attesa",
  smsPingChannelError: "Errore durante l’invio del messaggio al tuo numero.",
  smsSendText: "Mandami un SMS",
  smsStartTexting: "Inizia a inviare SMS",
  smsTooManyRequestsError:
    "Recentemente è stata richiesta una connessione per quel numero. Riprova tra {minutes} minuti.",
  smsTooManyRequestsOneMinuteError:
    "Recentemente è stata richiesta una connessione per quel numero. Riprova tra 1 minuto.",
  smsUnhandledError: "Si è verificato un problema. Riprova.",
  syncConversation: "Sincronizza conversazione",
  tapToRetry: "Messaggio non consegnato. Tocca per riprovare.",
  tapToRetryForm:
    "Modulo non inviato. Tocca un punto qualsiasi del modulo per riprovare.",
  telegramChannelDescription:
    "Collega il tuo account Telegram per ricevere una notifica quando ricevi una risposta e continuare la conversazione su Telegram",
  unsupportedMessageType: "Tipo di messaggio non supportato.",
  unsupportedActionType: "Tipo di azione non supportato.",
  uploadDocument: "File",
  uploadInvalidError: "File non valido",
  uploadPhoto: "Immagine",
  uploadVirusError: "È stato rilevato un virus nel file rifiutato",
  viberChannelDescription:
    "Collega il tuo account Viber per ricevere una notifica quando ricevi una risposta e continuare la conversazione su Viber. Per iniziare, scansiona il codice QR usando l’app Viber.",
  viberChannelDescriptionMobile:
    "Collega il tuo account Viber per ricevere una notifica quando ricevi una risposta e continuare la conversazione su Viber. Per iniziare, installa l’app Viber e tocca Connetti.",
  viberQRCodeError:
    "Si è verificato un errore durante il recupero del codice QR di Viber. Riprova.",
  wechatChannelDescription:
    "Collega il tuo account WeChat per ricevere una notifica quando ricevi una risposta e continuare la conversazione su WeChat. Per iniziare, scansiona questo codice QR usando l’app WeChat.",
  wechatChannelDescriptionMobile:
    "Collega il tuo account WeChat per ricevere una notifica quando ricevi una risposta e continuare la conversazione su WeChat. Per iniziare, salva questa immagine di codice QR e caricalo nell’<a href='weixin://dl/scan'>apposito scanner</a>.",
  wechatQRCodeError:
    "Si è verificato un errore durante il recupero del codice QR di WeChat. Riprova.",
  whatsappChannelDescriptionDesktop:
    "Sincronizza il tuo account con WhatsApp scansionando il codice QR o facendo clic sul link qui sotto.\nQuindi, invia il messaggio precompilato per convalidare la richiesta di sincronizzazione. (Il tuo {{code}}).",
  whatsappChannelDescriptionMobile:
    "Sincronizza il tuo account con WhatsApp facendo clic sul link qui sotto.\nQuindi, invia il messaggio precompilato per convalidare la richiesta di sincronizzazione. (Il tuo {{code}}).",
  whatsappLinkingError:
    "Si è verificato un errore durante il recupero delle informazioni di collegamento a WhatsApp. Riprova.",
  zBotErrorBanner: "Impossibile connettersi a Z Bot",
  zBotErrorButtonText: "Vai al modulo web",
  zBotErrorMessage:
    "Stiamo riscontrando un problema tecnico e non possiamo collegarti a Z Bot. Se hai bisogno di metterti in contatto con noi, il nostro modulo web è pronto per ricevere la tua richiesta.",
};
