import { getIframeDoc } from '../scripts/api';
import botWidgetIcon from '../images/default.gif';

let loading = true;

/**
 * Gets loading state value.
 * @returns {boolean} loading value
 */
export const getLoadingState = () => loading;

/**
 * Sets loading state value.
 * @param {boolean} loadingState - Desired loading state
 */
export const setLoadingState = (loadingState) => {
  if (loadingState) {
    loading = true;
  } else {
    loading = false;
  }
};

/**
 * Gathers list of controls to disable for loading state.
 * @returns {[?Element, ?Element]} List of conversation input elements
 */
const getConversationInputs = () => {
  const iframeDoc = getIframeDoc();
  const input = iframeDoc && iframeDoc.querySelector('.message-input');
  const customInput = iframeDoc && iframeDoc.querySelector('.custom-upload');
  return [input, customInput];
};

/**
 * Disables input controls before loading to ensure Z Bot starts the
 * conversation.
 * @param {[?Element, ?Element]} elements - List of conversation input elements
 * @param {boolean} disabled - Disable input elements
 */
const disableInputs = (elements, disabled) => {
  if (elements) {
    if (elements.length > 0) {
      elements.forEach((element) => {
        if (element) {
          // Would be costly to create new element and insert, setting disabled is low risk
          // eslint-disable-next-line no-param-reassign
          element.disabled = disabled;
        }
      });
    }
  }
};

//
/**
 * If loader element not present and visible is true, disables inputs, creates a
 * loading indicator element and shows a loading chat bubble icon while waiting
 * for Z Bot to respond, else hides the loader and re-enables inputs.
 * @param {boolean} visible - Show the loader
 * @param {string} element - Preferred name for indicator element
 */
export const showLoadingIndicator = (visible, element) => {
  const iframeDoc = getIframeDoc();
  if (iframeDoc) {
    const loader = iframeDoc.getElementById(`${element}Indicator`);
    const inputs = getConversationInputs();
    if (!loader) {
      if (visible) {
        setLoadingState(true);
        disableInputs(inputs, true);
        const loaderContainer = iframeDoc.getElementById(element);
        const loadingIndicator = window.document.createElement('div');
        // Speech bubble loader html
        loadingIndicator.insertAdjacentHTML(
          'beforeend',
          `<div class="typing-indicator-container fade-in"> <div \
        class="typing-indicator-avatar-placeholder"><div \
        class="msg-avatar"><img id="loadingIndicatorAvatar" \
        alt="Loading Indicator Avatar"/></div></div><div \
        class="typing-indicator typing-indicator-first"><span></span>\
        <span></span><span></span></div></div>`
        );
        loadingIndicator.setAttribute('id', `${element}Indicator`);
        loadingIndicator.setAttribute('class', 'loading-indicator');
        if (loaderContainer) {
          loaderContainer.insertAdjacentElement('beforeend', loadingIndicator);
          const inidcatorAvatar = iframeDoc.getElementById(
            'loadingIndicatorAvatar'
          );
          inidcatorAvatar.src = botWidgetIcon;
        }
        // Automatically hide loader after 10 seconds
        setTimeout(() => {
          loadingIndicator.remove();
          disableInputs(inputs, false);
        }, 10000);
      } else {
        setLoadingState(false);
      }
    } else {
      // Set loading false, remove loader, and re-enable inputs
      setLoadingState(false);
      if (!visible) loader.remove();
      disableInputs(inputs, false);
    }
  }
};
